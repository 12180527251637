import {Button} from "grommet/es6";
import React from "react";

class MainMenu extends React.Component {
    constructor(props) {
        super(props);
    this.state = {
        disable: this.props.disable,
    }
    }

    render(){

        const {disable} = this.state;

    return(
<div id="skill-tree" className="card">
    <div className="level-selector">
        <div
            className={
                disable === false
                    ? 'icon-background-circle'
                    : 'disabled-circle'
            }
        >

            <Button
                onClick={ () =>this.props.onClick(4)}>
                <img
                    className="game-icon"
                    src="https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png"
                    alt="Level One"
                />
            </Button>
        </div>
        <Button
            onClick={ () =>this.props.onClick(4)}
            className={disable === false ? '' : 'disabled-link'}
        >
            <h3>Lessons</h3>
        </Button>
    </div>
    <div className="level-selector">
        <div
            className={
                disable === false
                    ? 'icon-background-circle'
                    : 'disabled-circle'
            }
        >
            <Button
                onClick={ () => this.props.onClick(2)}>
                <img
                    className="game-icon"
                    src="https://chooter-images.s3.eu-west-2.amazonaws.com/icon02.png"
                    alt="Level One"
                />
            </Button>
        </div>
        <Button
            onClick={ () =>this.props.onClick(2)}
            className={disable === false ? '' : 'disabled-link'}
        >
            <h3>Critical Thinking</h3>
        </Button>
    </div>
    <div className="level-selector">
        <div
            className={
                disable === false
                    ? 'icon-background-circle'
                    : 'disabled-circle'
            }
        >
            <Button
                onClick={ () =>this.props.onClick(3)}>
                <img
                    className="game-icon"
                    src="https://chooter-images.s3.eu-west-2.amazonaws.com/icon03.png"
                    alt="Level One"
                />
            </Button>
        </div>
        <Button
            onClick={ () =>this.props.onClick(3)}
            className={disable === false ? '' : 'disabled-link'}
        >
            <h3>Problem Solving</h3>
        </Button>
    </div>
    <div className="level-selector">
        <div
            className={
                disable === false
                    ? 'icon-background-circle'
                    : 'disabled-circle'
            }
        >
            <Button
                onClick={ () =>this.props.onClick(1)}>
                <img
                    className="game-icon"
                    src="https://chooter-images.s3.eu-west-2.amazonaws.com/icon01.png"
                    alt="Level One"
                />
            </Button>
        </div>
        <Button
            onClick={ () =>this.props.onClick(1)}
            className={disable === false ? '' : 'disabled-link'}
        >
            <h3>Maths Skills</h3>
        </Button>
    </div>
    <div className="level-selector">
        <div
            className={
                disable === false
                    ? 'icon-background-circle'
                    : 'disabled-circle'
            }
        >
            <Button
                onClick={ () =>this.props.onClick(5)}>
                <img
                    className="game-icon"
                    src="https://chooter-images.s3.eu-west-2.amazonaws.com/icon05.png"
                    alt="Level One"
                />
            </Button>
        </div>
        <Button
            onClick={ () =>this.props.onClick(5)}
            className={disable === false ? '' : 'disabled-link'}
        >
            <h3>Past Paper Worked Examples</h3>
        </Button>
    </div>
</div>
    )}}

    export default MainMenu