import React from "react";
import _ from 'lodash';
import {zeroPad} from 'react-countdown-now';
import LoadingScreen from "../../components/LoadingScreen"
import QuestionContainer from "../QuestionContainer";
import {CountDownTimer} from "../../helpers/countdown";
import {millisToMinutesAndSeconds} from "../../helpers";
import {clockIcon} from "../../components/ClockIcon";
import {questionIcon} from "../../components/QuestionIcon";
import {Meter, Grommet, Box, Stack, Text, Button} from 'grommet';


import axios from "axios";
import validate from "validate.js";

axios.defaults.baseURL = 'https://chooter.herokuapp.com';

const GAME_TIME = 750;
const totalQuestions = 7;

class ProblemSolvingGameContainer extends React.Component {

    countdownRef;
    timeObj;

    constructor(props) {
        super(props);
        this.state = {
            isMultiSelect: true,
            randomQuestion:0,
            questionNumber: 0,
            usedQuestionsArray: [],
            answersArray: [],
            countdownTime: 0,
            startGameTime: 0,
            gameTime: 0,
            isGameEnded: false,
            isStartGameVisible: true,
            answerReview: false,
            questions: [],
            loading: false,
            initialAssessment: false,
            buttonUnset: true,
            timeVisible: true
        };
        this.submitAndMoveQuestion = this.submitAndMoveQuestion.bind(this);
        this.startGame = this.startGame.bind(this);
        this.handleCountdown = this.handleCountdown.bind(this);
        this.handleEnterKey = this.handleEnterKey.bind(this);
        this.reviewAnswersToggle = this.reviewAnswersToggle.bind(this);
        this.renderGameOverDiv = this.renderGameOverDiv.bind(this);
        this.toggleCountdown = this.toggleCountdown.bind(this);
    }

    componentDidMount() {
        this.initializeCountdown(GAME_TIME);
        document.addEventListener('keyup', this.handleEnterKey)
        if(this.props.profile.scoreB === 0){
            this.setState({initialAssessment: true})
        }

    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleEnterKey);
    }

    submitResult() {
        const {answersArray, gameTime} = this.state;
        let correctAnswers = _.sumBy(
            answersArray,
            (a) => {
                return a.isAnswerCorrect === true
            }
        );

        //push to backend here

        return {correctAnswers, answersArray, gameTime};
    }

    parentGetAnswers(childGetAnswers) {
        this.childGetAnswers = childGetAnswers;
    }

    validateAnswer(answersArray, currentQuestion) {
        let correctAnswers = [];
        _.forEach(currentQuestion.AnswerOptions, function(option){
            if(option.correct){
                correctAnswers.push(option.text);}
        });

        return _.isEqual(_.sortBy(answersArray), _.sortBy(correctAnswers));
    }


    startGame() {
        this.setState({loading: true});
        axios.get(`/getRandomQuestions`, {
            params: {
                types: ['Problem Solving']
            }
        })
            .then(res => {
                const importedQuestions = res.data;
                this.setState({questions: importedQuestions});
                let now = Date.now();
                this.setState({isGameEnded: false, questionNumber: 0, isStartGameVisible: false, answerReview: false, startGameTime: now, countdownTime: now + GAME_TIME, loading: false});
                this.countdownRef.start();
            });
    };

    endGame() {
        this.setState({isGameEnded: true});
    }

    initializeCountdown(time) {
        this.countdownRef = new CountDownTimer(time);
        this.timeObj = this.countdownRef.parse(time);
        this.countdownRef.onTick(this.handleCountdown);
    }

    handleCountdown(minutes, seconds) {
        let countdown = `${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`;
        this.setState({countdownTime: countdown});
        if (!this.countdownRef.running) {
            this.endGame();
        }
    }

    handleEnterKey(e) {
        const {isGameEnded, isStartGameVisible} = this.state;
        if (!isGameEnded && !isStartGameVisible && e.key === 'Enter') {
            this.submitAndMoveQuestion();
        }
        if (!isGameEnded && isStartGameVisible && e.key === 'Enter') {
            this.startGame();
        }
    }


    submitAndMoveQuestion() {
        const {questionNumber, isGameEnded, questions} = this.state;

        if (!isGameEnded) {
            let currentQuestion = questions[questionNumber];
            let {answersArray, questionTimeTaken} = this.childGetAnswers();
            let isAnswerCorrect = this.validateAnswer(answersArray, currentQuestion);
            let answerObject = {
                questionID: currentQuestion.id,
                questionObject: currentQuestion,
                isAnswerCorrect: isAnswerCorrect,
                timeTaken: questionTimeTaken
            };
            let A = null,B = null,C = null,D = null,E = null, skipped = false;

            //drill timeTaken from QuestionContainer
            if(answersArray.length>0){
                A = answersArray[0];
                if(answersArray.length>1){
                    B = answersArray[1];
                }
                if(answersArray.length>2){
                    C = answersArray[2];
                }
                if(answersArray.length>3){
                    D = answersArray[3];
                }
                if(answersArray.length>4){
                    E = answersArray[4];
                }
            } else {skipped = true}

            axios.post(`/addUserStatementAnswer`,{
                "questionId": currentQuestion.uuid,
                "optionIdA": A,
                "optionIdB": B,
                "optionIdC": C,
                "optionIdD": D,
                "optionIdE": E,
                "userId": this.props.profile.uuid,
                "timeTaken": questionTimeTaken,
                "skipped": skipped,
                "correct": isAnswerCorrect});

            this.checkIfNextQuestionAvailable(answerObject);
        }
    }


    checkIfNextQuestionAvailable(answerObject) {
        //CHANGE TO 21
        if (this.state.questionNumber + 1 < totalQuestions) {
            this.setState(prevState => ({
                questionNumber: this.state.questionNumber + 1,
                answersArray: [...prevState.answersArray, answerObject],

            }));
        } else {
            this.countdownRef.stopTimer();
            this.setState(prevState => ({
                questionNumber: this.state.questionNumber + 1,
                isGameEnded: true,
                gameTime: Date.now() - prevState.startGameTime,
                answersArray: [...prevState.answersArray, answerObject]
            }))
        }
        console.log(this.state.answersArray)
    }

    renderCountdown() {
        if (this.state.countdownTime !== 0 && !this.state.isGameEnded) {
            return (
                <span className="countdown">
          {clockIcon()}
                    <span>{this.state.countdownTime}</span>
          <span>TIME LEFT</span>
        </span>
            )
        } else {
            return (
                <span className="countdown">
          {clockIcon()}
                    <span>00:00</span>
        </span>
            )
        }
    }

    toggleCountdown(){
        const {timeVisible} = this.state;
        this.setState({timeVisible: !timeVisible})
    }

    renderQuestionsAnswered() {
       const {active,questionNumber,label, hover} = this.state;
        let hoverValueAnswered;
        let hoverValueToCome;
        questionNumber===0 ? hoverValueAnswered = '0':hoverValueAnswered=questionNumber;
        totalQuestions - (questionNumber + 1) ===0 ? hoverValueToCome = '0':hoverValueToCome=totalQuestions - (questionNumber + 1);
        return (
            <div className="questionsAnswered">
                {questionIcon()}
                <Grommet>
                    <Box align="center">
                        <Stack anchor="center">
                            <Meter
                                type="circle"
                                values={[
                                    {
                                        color: '#7D4CDB',
                                        value: questionNumber + 1,
                                        highlight: (hover === 'First'),
                                        onHover: over =>
                                            this.setState({
                                                hover: over ? 'First' : undefined,
                                                active: over ? hoverValueAnswered : 0,
                                                label: over ? "answered" : undefined
                                            })
                                    },
                                    {
                                        color: '#b9b3ff',
                                        value: totalQuestions - (questionNumber + 1),
                                        highlight: (hover === 'Second'),
                                        onHover: over =>
                                            this.setState({
                                                hover: over ? 'Second' : undefined,
                                                active: over ? hoverValueToCome : 0,
                                                label: over ? "to come" : undefined
                                            })
                                    }
                                ]}
                                size="medium"
                                thickness="medium"
                            />
                            <Box align="center">
                                <Box direction="column" align="center">
                                    <Text size="medium" weight="bold">
                                        {active || totalQuestions - questionNumber}
                                    </Text>
                                    <Text size="medium" weight="bold">{questionNumber+1<totalQuestions ? "Questions" : "Question"}</Text>
                                </Box>
                                <Text>{label || (questionNumber + 1 < totalQuestions ? "remain" : "remains")}</Text>
                            </Box>
                        </Stack>
                    </Box>
                </Grommet>
            </div>
        )
    }

    renderQuestionContainer(currentQuestion, isMultiSelect) {
        return (
            <QuestionContainer isMultiSelect={isMultiSelect}
                               currentQuestion={currentQuestion}
                               shareMethod={this.parentGetAnswers.bind(this)}
            />
        )
    }

    checkIfPhotoAnswer(answer) {
        return validate({website: answer}, {
            website: {
                url: {
                    allowLocal: true,
                }}});}

    renderAnswerReview(){
        let correctArray = []; // correctly answered question text
        let correctAnswerArrayCorrect = []; //correct answers for correctly answered Qs
        let incorrectArray = [];
        let correctAnswerArrayIncorrect = [];
        let result = this.submitResult();

        function answerAllocation(element){
            let pushArrayCorrect = [];
            let pushArrayIncorrect = [];

            if(element.isAnswerCorrect){
                correctArray.push(element);
                _.forEach(element.questionObject.AnswerOptions, function (e) {
                    if (e.correct) {
                        pushArrayCorrect.push(e.text);}
                })}else{
                incorrectArray.push(element);
                _.forEach(element.questionObject.AnswerOptions, function (e) {
                    if (e.correct) {
                        pushArrayIncorrect.push(e.text);}
                })
            }

            if(pushArrayCorrect.length>0){
                correctAnswerArrayCorrect.push(pushArrayCorrect)
            }
            if(pushArrayIncorrect.length>0){
                correctAnswerArrayIncorrect.push(pushArrayIncorrect)
            }
        }

        this.state.answersArray.forEach(answerAllocation);


            let correctAnswerReview = correctArray.map((object, i) =>
            <p key={i}>
                {i+1}: {object.questionObject.statement}<br/>
                <br/>
                {object.questionObject.intro}<br/>
                {object.questionObject.image.length > 0 ?  <img style = {{width: '50%'}} src={object.questionObject.image} alt={`question ${i}`}/>  : ''}
                <br/>
                Correct Answer:{this.checkIfPhotoAnswer(correctAnswerArrayCorrect[i]) === undefined ?  correctAnswerArrayCorrect[i].map(x => <img src = {x} alt = 'correct answer' />) : correctAnswerArrayCorrect[i].map(x => <p>{x}</p>)}
                <br/>
                <hr/>
                <br/>
            </p>);



        let incorrectAnswerReview = incorrectArray.map((object, i) =>
            <p key={i}>
                <span>{i+1}: {object.questionObject.statement}<br/></span>
                <br/>
                {object.questionObject.intro}<br/>
                {object.questionObject.image.length > 0 ?  <img style = {{width: '50%'}} src={object.questionObject.image} alt={`question ${i}`}/>  : ''}
                <br/>
                Correct Answer:{this.checkIfPhotoAnswer(correctAnswerArrayIncorrect[i][0]) === undefined ?  correctAnswerArrayIncorrect[i].map(x => <img src = {x} alt = 'correct answer' />) : correctAnswerArrayIncorrect[i].map(x => <p>{x}</p>)}
                <br/>
                <hr/>
                <br/>
            </p>);


        return(<div
                className="ReviewAnswerWrapper">
                <h2>Review Answers</h2>
                <br/>
                <button onClick={this.reviewAnswersToggle}
                > Go Back </button>
                <br/>
                <h3>{result.correctAnswers} correct</h3>
                {correctAnswerReview}
                <h3>{this.state.questionNumber - result.correctAnswers} incorrect</h3>
                {incorrectAnswerReview}

                <button onClick={this.reviewAnswersToggle}
                > Go Back </button>
            </div>
        )}


    renderStartGameDiv() {
        return (
            <div className="startGameWrapper">
                <h2>Welcome to the Problem Solving prototype!</h2>
                <p>The game will continue until you answer <span>{totalQuestions}</span> questions or run out of time <span>(12:30)</span></p>
                <p>You can submit an answer by clicking on the <span>Submit Answer</span> button or by
                    pressing <span>Enter</span> key on the keyboard</p>
                <p>Click the button below or press Enter to start playing!</p>
                <button onClick={this.startGame}>START GAME</button>
                <button onClick={this.props.onFinish}>BACK</button>
            </div>
        )
    }

    reviewAnswersToggle(){
        if(!this.state.answerReview){
            this.setState({
                answerReview: true
            });}
        else{
            this.setState({
                answerReview: false
            });
        }
    }

    renderGameOverDiv() {
        let result = this.submitResult();
        if(!this.state.initialAssessment && this.state.buttonUnset){
            this.setState({endButton: <button onClick={this.startGame}
                > Restart </button>, buttonUnset: false})
        }

        let scoredTime;
        if((750 - (result.gameTime/1000))>180){
            scoredTime = 180
        }
        else{
            scoredTime = Math.floor(750 - (result.gameTime/1000))
        }

        let rawScoreValue = Math.floor(100*(14.66*Math.pow(0.18*result.correctAnswers-0.635,3)+3.75));
        let TimeValue = Math.floor(1000 * (Math.pow(0.00097*scoredTime-0.25,3)+0.015625));

        console.log(rawScoreValue,TimeValue);

        let scoretoPush;

        if(this.props.profile.scoreC === 0){
            scoretoPush = rawScoreValue + Math.floor(TimeValue * result.correctAnswers)
        }
        else{
            scoretoPush = this.props.profile.scoreB + ((rawScoreValue + Math.floor(TimeValue * result.correctAnswers)) - this.props.profile.scoreC)/1.8
        }
        axios.post(`/updateUserScore`, {
                email: this.props.profile.email,
                scoreName: 'scoreC',
                scoreValue:  scoretoPush
        });

        return (
            <div className="gameOverWrapper">
                <h2>GAME OVER</h2>
                <div className="gameOverResult">
                    <p>
                        {clockIcon()}
                        <span>Game Time:</span>
                        <span>{millisToMinutesAndSeconds(result.gameTime)}</span>
                    </p>
                    <p>
                        {questionIcon()}
                        <span>Correct answers:</span>
                        <span>{result.correctAnswers}</span>
                    </p>
                </div>
                <button onClick={this.reviewAnswersToggle}
                > Review Answers </button>
                <button onClick={this.props.onFinish}>Finish</button>
                {this.state.endButton}
                {/*<pre>{JSON.stringify(result, null, 4)}</pre>*/}
            </div>
        )
    }


    renderGameContainer(currentQuestion, isMultiSelect) {
        const {isGameEnded, isStartGameVisible, answerReview, loading, timeVisible} = this.state;

        if (!isGameEnded && isStartGameVisible && !loading) {
            return this.renderStartGameDiv();
        }

        if (loading) {
            return <LoadingScreen/>;
        }

        if (isGameEnded && !isStartGameVisible && !answerReview) {
            return this.renderGameOverDiv();
        }

        if(isGameEnded && !isStartGameVisible && answerReview){
            return this.renderAnswerReview();
        }

        return (
            <div className="gamePlayWrapper">
                <div className="quizTitle">CHOOTER QUIZ</div>
                <div className="gamePlayWrapperInner">
                    <div className="game">
                        {this.renderQuestionContainer(currentQuestion, isMultiSelect)}
                    </div>
                    <div className="controls">
                        {timeVisible? this.renderCountdown():''}
                        <Button label = {!timeVisible ? "Reveal Clock":"Hide Clock"} onClick={this.toggleCountdown} style = {{left: '0', background: !timeVisible ? '#7D4CDB':'#b9b3ff'}}/>
                        {this.renderQuestionsAnswered()}
                        <button className="submit" onClick={this.submitAndMoveQuestion}
                        >SUBMIT</button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let {questions, isMultiSelect, questionNumber} = this.state;

        let currentQuestion = questions[questionNumber];

        return (
            <div className="gameWrapper">
                {this.renderGameContainer(currentQuestion, isMultiSelect)}
            </div>
        )
    }
}

export default ProblemSolvingGameContainer
