export const bubbleColorsArray = [
  '0xffffff',
  '0xffefd5',
  '0xffe4e1',
  '0xe6e6fa',
  '0xe0eee0',
  '0xffdead',
  '0x00fa9a',
  '0xffd700',
  '0xfa8072',
  '0xeee8aa',
  '0xfffafa',
  '0xf5f5f5',
  '0xfdf5e6',
  '0xffe4c4',
  '0xfffacd',
  '0xf0fff0',
  '0xffe4e1',
  '0xe6e6fa',
  '0xf0ffff',
  '0xe0eee0',
  '0xf0f8ff',
  '0xafeeee',
  '0xe0ffff',
  '0x87cefa',
  '0x00bfff',
  '0x98fb98',
  '0xf0e68c',
  '0xffa07a',
  '0xdda0dd',
  '0xffb6c1',
];
