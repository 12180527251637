import React from 'react';
import PropTypes from "prop-types";
import {Box} from 'grommet';
//import {askQuestionIcon} from "../../components/AskQuestionIcon";

class Question extends React.Component {
  renderNumeration(id) {
 //   return `${id + 1}. `;
  }

  render() {
    const {intro, statement, id, image} = this.props;
    const styles = {
        img:{maxWidth: '80%',
            margin: '2%'}
    };

    let imageCheck = null;

    if(image !== null){
    if(image.length > 0){
        imageCheck = image
    }
    }

    if(imageCheck === null){return(
      <React.Fragment>
          <Box border={{ color: '#13334B', size: 'medium'}} pad='medium' round='medium'>
        <div className="questionIntro">
            {/*{askQuestionIcon()}*/}
            <span>{this.renderNumeration(id)}</span>
            <span>{statement}</span>
        </div>
          </Box>
        <div className="questionStatement">
          <div>
              <span>{intro}</span>
          </div>
        </div>
      </React.Fragment>
    )}else{
        return(
            <React.Fragment>
                <Box border={{ color: '#13334B', size: 'medium'}} pad='medium' round='medium'>
            <div className="questionIntro">
                {/*{askQuestionIcon()}*/}
                <span>{this.renderNumeration(id)}</span>
                <span>{statement}</span>
            </div>
                </Box>
            <img src={imageCheck} alt="Question"
                 style={styles.img}
            />
            <div className="questionStatement">
                <div>
                    <span>{intro}</span>
                </div>
            </div>
        </React.Fragment>
    )}
  }
}

Question.propTypes = {
  intro: PropTypes.string,
  statement: PropTypes.string,
  id: PropTypes.number,
};

export default Question
