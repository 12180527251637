import {Button} from "grommet/es6";
import { FaStar } from 'react-icons/fa'
import React from "react";
import axios from "axios";
import LoadingScreen from "../../components/LoadingScreen";
import PropTypes from "prop-types";
import _ from "lodash";

axios.defaults.baseURL = 'https://chooter.herokuapp.com';

const imageArray = ["https://chooter-images.s3.eu-west-2.amazonaws.com/icon01.png", "https://chooter-images.s3.eu-west-2.amazonaws.com/icon02.png", "https://chooter-images.s3.eu-west-2.amazonaws.com/icon03.png", "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png"];

const problemSolvingOptions = [{lessonID:[8], title:['Mathematical techniques'], axiosCall: 'math', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Introduction to aptitude testing "},
    {lessonID:[9], title:['Constructing equations'], axiosCall: 'constructingEquations', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon02.png", alt: "Introduction to aptitude testing"},
    {lessonID:[10], title:['Spatial reasoning'], axiosCall: 'spatialReasoning', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Introduction to aptitude testing"},
    {lessonID:[11,12], title:['Making diagrams', 'Making diagrams part 2'], axiosCall: 'makingDiagrams', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon02.png", alt: "Introduction to aptitude testing"},
    {lessonID:[14], title:['Dealing with tables'], axiosCall: 'tables', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon02.png", alt: "Introduction to aptitude testing"},
    {lessonID:[15], title:['Weird looking questions'], axiosCall: 'weird', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Introduction to aptitude testing"}];

const criticalThinkingOptions = [{lessonID:[3,4], title:['Identifying a conclusion','Identifying a conclusion part 2'], axiosCall: 'identifyingConclusion', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Identifying a conclusion I"},
    {lessonID:[5,6], title:['Identifying an assumption', 'Identifying an assumption part 2'], axiosCall: 'identifyingAssumption', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Identifying an assumption"},
    {lessonID:[7], title:['Strengthening, weakening and underlying principles'], axiosCall: 'strengthWeakUnderlying', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Strengthening, weakening and underlying principles"},
    {lessonID:[19], title:['Necessary & Sufficient'], axiosCall: 'necessarySufficient', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Necessary & Sufficient"},
    {lessonID:[20], title:['Paralleling reasoning'], axiosCall: 'parallelReasoning', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Paralleling reasoning"},
    {lessonID:[21], title:['Underlying principles'], axiosCall: 'underlyingPrinciples', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Underlying principles"},
    ];

const TSAoptions = [{lessonID:[13], title:['Introduction to aptitude testing'], axiosCall: 'aptitudeIntro', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Introduction to aptitude testing"},
    {lessonID:[1,2], title:['Introduction to the TSA','Introduction to the TSA part 2'], axiosCall: 'TSAIntro', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Introduction to the TSA"},
    {lessonID:[16,17,18], title:['The TSA essay','The TSA essay part 2','The TSA essay part 3'], axiosCall: 'TSAEssay', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Introduction to aptitude testing"},
    {lessonID:[22], title:['TSA essay example 1'], axiosCall: 'TSAEssayExample', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon05.png", alt: "TSA essay example"},
    ];
const BMAToptions = [{lessonID:[13], title:['Introduction to aptitude testing'], axiosCall: 'aptitudeIntro', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Introduction to aptitude testing"}];
const UKCAToptions = [{lessonID:[13], title:['Introduction to aptitude testing'], axiosCall: 'aptitudeIntro', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Introduction to aptitude testing"}];
const Joboptions = [{lessonID:[13], title:['Introduction to aptitude testing'], axiosCall: 'aptitudeIntro', src: "https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt: "Introduction to aptitude testing"}];

const JobInnerOptions = [{title: 'Introduction', arrayOption: Joboptions, src: 'https://chooter-images.s3.eu-west-2.amazonaws.com/icon01.png', alt: 'Introduction'}, {title: 'Problem Solving', arrayOption: problemSolvingOptions, src: 'https://chooter-images.s3.eu-west-2.amazonaws.com/icon02.png', alt: 'Problem Solving'}, {title: 'Critical Thinking', arrayOption: criticalThinkingOptions, src: 'https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png', alt: 'Critical Thinking'}];
const UKCATInnerOptions = [{title: 'Introduction', arrayOption: UKCAToptions, src: 'https://chooter-images.s3.eu-west-2.amazonaws.com/icon01.png', alt: 'Introduction'}, {title: 'Problem Solving', arrayOption: problemSolvingOptions, src: 'https://chooter-images.s3.eu-west-2.amazonaws.com/icon02.png', alt: 'Problem Solving'}];
const BMATInnerOptions = [{title: 'Introduction', arrayOption: BMAToptions, src: 'https://chooter-images.s3.eu-west-2.amazonaws.com/icon01.png', alt: 'Introduction'}, {title: 'Problem Solving', arrayOption: problemSolvingOptions, src: 'https://chooter-images.s3.eu-west-2.amazonaws.com/icon02.png', alt: 'Problem Solving'}, {title: 'Critical Thinking', arrayOption: criticalThinkingOptions, src: 'https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png', alt: 'Critical Thinking'}];
const TSAInnerOptions = [{title: 'Introduction', arrayOption: TSAoptions, src: 'https://chooter-images.s3.eu-west-2.amazonaws.com/icon01.png', alt: 'Introduction'}, {title: 'Problem Solving', arrayOption: problemSolvingOptions, src: 'https://chooter-images.s3.eu-west-2.amazonaws.com/icon02.png', alt: 'Problem Solving'}, {title: 'Critical Thinking', arrayOption: criticalThinkingOptions, src: 'https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png', alt: 'Critical Thinking'}];

const outerOptions = [{menuTitle:"TSA Lessons", axiosCall: 'overallTSA', arrayOption: TSAInnerOptions, src:"https://chooter-images.s3.eu-west-2.amazonaws.com/icon01.png", alt:"Level One"},
    {menuTitle:"BMAT Lessons", axiosCall: 'overallBMAT', arrayOption: BMATInnerOptions, src:"https://chooter-images.s3.eu-west-2.amazonaws.com/icon02.png", alt:"Level One"},
    {menuTitle:"UKCAT Lessons", axiosCall: 'overallUKCAT', arrayOption: UKCATInnerOptions, src:"https://chooter-images.s3.eu-west-2.amazonaws.com/icon03.png", alt:"Level One"},
    {menuTitle:"Job assessment Lessons", axiosCall: 'overallJob', arrayOption: JobInnerOptions, src:"https://chooter-images.s3.eu-west-2.amazonaws.com/icon04.png", alt:"Level One"}];


class LessonMenu extends React.Component {

    static propTypes = {
        auth: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            outerMenu: true,
            innerMenu: false,
            innerInnerMenu: false,
            innerMenuObject: null,
            innerInnerMenuObject: null,
            optionsLength: null,
            setLength: true,
            lessonData: [],
            requestSent: false
        };

        this.renderInnerInnerMenu = this.renderInnerInnerMenu.bind(this);
    }



    getUserLessonData() {
        this.setState({requestSent: true});
        const auth = this.props.auth;
        axios.get(`/getUserLessonData`, {
            params: {
                cognitoUUID: auth.info.idToken.payload.sub
            }
        }).then(res => {
            if (res.data) {
                    this.setState({
                        lessonData: res.data,
                        loading: false

                    });
            } else {
                    axios.post(`/addUserLessonData`, {
                        cognitoUUID: auth.info.idToken.payload.sub
                    });
                    this.setState({loading: false})
                    }
                console.log('done');

                })}


    renderOuterMenu(){
        const {disable} = this.state;
        return(
            outerOptions.map((obj, index) =>

            <div className="level-selector" key ={index}>
                <div
                    className={
                        disable === false
                            ? 'icon-background-circle'
                            : 'disabled-circle'
                    }
                >

                    <Button
                        onClick={ () => this.chooseInnerMenu(obj.arrayOption)}>
                        <img
                            className="game-icon"
                            src={obj.src}
                            alt={obj.alt}
                        />
                    </Button>
                </div>
                <Button
                    onClick={ () =>this.chooseInnerMenu(obj.arrayOption)}
                    className={disable === false ? '' : 'disabled-link'}
                >
                    <h3>{obj.menuTitle}</h3>
                </Button>
            </div>))
    }

    renderInnerMenu(){
        const {disable, innerMenuObject} = this.state;
        return(
            innerMenuObject.map((obj, index) =>

                <div className="level-selector" key ={index}>
                    <div
                        className={
                            disable === false
                                ? 'icon-background-circle'
                                : 'disabled-circle'
                        }
                    >

                        <Button
                            onClick={ () => this.chooseInnerInnerMenu(obj.arrayOption)}>
                            <img
                                className="game-icon"
                                src={obj.src}
                                alt={obj.alt}
                            />
                        </Button>
                    </div>
                    <Button
                        onClick={ () => this.chooseInnerInnerMenu(obj.arrayOption)}
                        className={disable === false ? '' : 'disabled-link'}
                    >
                        <h3>{obj.title}</h3>
                    </Button>
                </div>))
    }

    renderInnerInnerMenu(){
        const {disable, innerInnerMenuObject,lessonData, setLength} = this.state;
        let numberOfTotalQuestions = 0;
        let numberOfCompletedQuestions = 0;
        _.forEach(innerInnerMenuObject, function(option){
            numberOfTotalQuestions += option.lessonID.length;
            if (lessonData[option.axiosCall] <= option.lessonID.length){
                numberOfCompletedQuestions += lessonData[option.axiosCall]
            }else{
                numberOfCompletedQuestions += option.lessonID.length
            }

        });
        if(setLength){
        if(numberOfTotalQuestions === numberOfCompletedQuestions){
            this.setState({optionsLength: `You've completed ${numberOfCompletedQuestions} out of ${numberOfTotalQuestions} lessons in this section. Good job!`, setLength: false});
        }else{
            this.setState({optionsLength: `You've completed ${numberOfCompletedQuestions} out of ${numberOfTotalQuestions} lessons in this section. Keep going!`, setLength: false});
        }}

        return(
            innerInnerMenuObject.map((obj, index) =>

                <div className="level-selector" key ={index}>
                    <div
                        className={
                            disable === false
                                ? 'icon-background-circle'
                                : 'disabled-circle'
                        }
                    >

                        <Button
                            onClick={
                                obj.lessonID.length > 1 ?
                                    lessonData[obj.axiosCall] < obj.lessonID.length
                                        ? () => this.props.onClick(obj.lessonID[lessonData[obj.axiosCall]], obj.axiosCall, obj.title[lessonData[obj.axiosCall]])
                                        : () => this.chooseCompletedLessonMenu(obj.lessonID)
                                    : () => this.props.onClick(obj.lessonID[0], obj.axiosCall, obj.title[0])
                            }
                        >
                            <img
                                className="game-icon"
                                src={obj.src}
                                alt={obj.alt}
                            />
                        </Button>
                    </div>
                        <Button
                            onClick={
                             obj.lessonID.length > 1 ?
                                    (lessonData[obj.axiosCall] < obj.lessonID.length
                                        ? () => this.props.onClick(obj.lessonID[lessonData[obj.axiosCall]], obj.axiosCall, obj.title[lessonData[obj.axiosCall]])
                                        : () => this.props.onClick(obj.lessonID[0], obj.axiosCall, obj.title[0]))    //this.chooseCompletedLessonMenu(obj.lessonID))
                                : () => this.props.onClick(obj.lessonID[0], obj.axiosCall, obj.title[0])
                            }
                            className={disable === false ? '' : 'disabled-link'}
                    >
                        <h3> {lessonData[obj.axiosCall] < obj.lessonID.length ? obj.title[lessonData[obj.axiosCall]] : obj.title[0]}</h3>
                    </Button>
                    <div>
                    {obj.lessonID.map((index, i) =>
                        <FaStar
                            key = {index}
                            color = {lessonData[obj.axiosCall] > i ? 'gold':'gray'}
                            size = {'4vh'}
                        />
                    )}
                    </div>

                </div>))
    }

    renderCompletedLessonMenu(lessonArray){
        let mapArray = lessonArray.map((obj, index) =>

            <div className="level-selector" key ={index}>
                <div>

                    <Button
                        onClick={ () => this.props.onClick(lessonArray[index], null)}>
                        <img
                            className="game-icon"
                            src={imageArray[index]}
                            alt={`part ${index + 1}`}
                        />
                    </Button>
                </div>
                <Button
                    onClick={ () => this.props.onClick(lessonArray[index], null)}
                >
                    <h3>Part {index + 1}</h3>
                </Button>
            </div>);
        return(
            <div>
            <h2>Which lesson do you want to recap?</h2>
            {mapArray}
            </div>
            )
    }

    chooseInnerMenu(optionsID){
        this.setState({
            innerMenuObject: optionsID,
            outerMenu: false,
            innerMenu: true,
            innerInnerMenu: false,
            completedMenu: false,
            setLength: true
        })
    }

    chooseInnerInnerMenu(optionsID){
    this.setState({   innerInnerMenuObject: optionsID,
                      outerMenu: false,
                      innerMenu: false,
                      innerInnerMenu: true,
                      completedMenu: false,
                      setLength: true
                  })
    }

    chooseCompletedLessonMenu(){
        this.setState({ outerMenu: false,
                        innerMenu: false,
                        innerInnerMenu: false,
                        completedMenu: true,
                        setLength: true})
    }

    renderChosenMenu(){
        if(this.state.outerMenu){
            return (this.renderOuterMenu())
        }else if(this.state.innerMenu){
            return (this.renderInnerMenu())
        }else if(this.state.innerInnerMenu){
            return (this.renderInnerInnerMenu())
        }else{
            return (this.renderCompletedLessonMenu())
        }
    }

    closeInnerMenu(){
        this.setState({
            outerMenu: true,
            innerMenu: null,
            innerInnerMenu: false,
            setLength: true
        })
    }

    renderCloseButton(){
        if(!this.state.outerMenu){
        return(<Button
            onClick = {() => this.closeInnerMenu()}
            label = {'Back'}
        />)}else{
            return(
                <Button
                    onClick = {this.props.close()}
                    label = {'Back'}
                />
            )
        }
    }

    refreshLessonData(){
        this.setState({
            loading: true,
            requestSent: false})
    }

    render(){
        const {requestSent, loading, innerMenu, innerMenuObject, innerInnerMenu, outerMenu, optionsLength, setLength, /*innerInnerMenuObject*/} = this.state;

        if(!requestSent && this.props.auth !== undefined){
            this.getUserLessonData()
        }

        if(optionsLength === null || setLength){
        if(outerMenu){
            this.setState({optionsLength: `${outerOptions.length} categories`, setLength: false})
        }else if (innerMenu){
            this.setState({optionsLength: `${innerMenuObject.length} lessons`, setLength: false})
        }
        //else {this.setState({optionsLength: `${innerInnerMenuObject.length} lessons`, setLength: false})}
        }
        if(loading){
            return (<LoadingScreen/>)
        }
        return(
            <div id="skill-tree" className="card">
                {!innerInnerMenu ? <h3>There are {optionsLength} available right now!</h3> : <h3>{optionsLength}</h3>}
                {this.renderCloseButton()}
                {this.renderChosenMenu()}
            </div>
        )}}

export default LessonMenu