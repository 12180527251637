import StarRatings from 'react-star-ratings';
import React from 'react';
import axios from 'axios';
axios.defaults.baseURL = 'https://chooter.herokuapp.com';

class WorkedExampleStarRating extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rated: false
        };
        this.changeRating = this.changeRating.bind(this);
    }

    changeRating(newRating) {
            this.setState({
                rated: true,
                rating: newRating
            });

        axios.post(`/addWorkedExampleRating`, {
            workedExampleID: this.props.workedExampleID,
            rating: newRating,
            userUUID : this.props.auth.info.idToken.payload.sub
        });

    }

    render() {
        if(!this.state.rated){
        return (
            <div>
            <h3>How useful was this worked example?</h3>
                <br/>
            <StarRatings
                rating={this.state.rating}
                starRatedColor="blue"
                changeRating={this.changeRating}
                numberOfStars={5}
                name='rating'
            />
            </div>
        );}
        else return (<p>Thanks for rating!</p>)
    }
}

export default WorkedExampleStarRating
