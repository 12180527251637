import * as Phaser from 'phaser'
import MathGame from "../MathGame";
import GameOver from "../GameOver";
import WelcomeScreen from "../WelcomeScreen";

class PhaserGame extends Phaser.Game {
  constructor(react) {
    const config = {
      type: Phaser.CANVAS,
      width: 800,
      height: 700,
      parent: 'gameContainer',
      physics: {
        default: 'arcade',
        arcade: {
          debug: false
        }
      },
      fps: {
        target: 60,
        min: 30,
      },
      scene: [WelcomeScreen, MathGame, GameOver]
    };
    super(config);
    this.react = react
  }
}

export default PhaserGame
