import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from '../Lotties/410-lego-loader.json'

class LegoAnimation extends Component {


    render(){

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return(
            <Lottie options={defaultOptions}
                    height={250}
                    width={250}
            />
        )
    }
}

export default LegoAnimation