import React from 'react'
import { state } from 'aws-cognito-redux-saga'
import axios from "axios";
import {Paper, Card} from "material-ui";
import {Button} from 'grommet';
import {Play} from 'grommet-icons'

axios.defaults.baseURL = 'https://chooter.herokuapp.com';

/*const style = {
    page: {},
    layout: {},
    heading: {
        textAlign: 'center',
        fontSize: '48px',
        margin: '64px'
    },
    h2: {
        color: 'orange',
        fontSize: '3vh',
        //marginBottom: '25px',
        textAlign: 'center'
    },
    h3Orange: {
        color: 'orange',
        fontSize: '150%',
        //marginBottom: '25px',
        textAlign: 'left'
    },
    h3Black: {
        color: 'black',
        fontSize: '150%',
        //marginBottom: '25px',
        textAlign: 'left'
    },
    landing: {
        width: 'auto',
        maxHeight: '60vh',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: 'center',
        alignContent: 'center',
        marginTop: '16px'
    },
    landingText: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '2vh',
        height: '90%'
    },
    paper: {
        padding: '5%',
        width: '50%',
        height: 'auto'

    },
    titleCard: {
        padding: '10px',
        square: 'false',
        rounded: 'true',
    },
    img: {
        padding: '16px',
        width: '50%',
        paddingTop:'34%',
        paddingBottom:'34%',
    },
    button:{
        align: 'left',
        primary: 'true',
        margin:{top: '2%',
                bottom: '0'},
        backgroundColor: 'orange',
        color: 'white',
        fontWeight: 'bold',
        borderRadius: '10px',
        fontSize: '1.5vh',
        hoverIndicator: true,
        padding: '1%',
        maxHeight: '13%',
        maxWidth: '30vw'
    }
};*/


export class LandingComponent extends React.Component {

    componentDidMount() {
        //wake up Heroku server
        axios.get(`/getRandomQuestions`)
    }

    render(){
        const { auth } = this.props;

        if (auth.isSignedIn === state.AUTH_SUCCESS){
            return(
                <div className="startGameWrapper">
                    <h2>Thanks for signing up!</h2>
                    <p>This is the first version of the prototype, there are a lot of updates on the way</p>
                    <p>If you have any questions get in touch at <b>david@chooter.co.uk</b></p>
                    <p>Check back often to see newly added content and features!</p>
                    <h5>The Chooter team</h5>
                    <form action = "/#/app">
                        <button>Let's get started!</button>
                    </form>
                </div>
            )}
        return (

            <div className="landingPageWrapper">
                <div className="layout">
                    <br/>
                        <Card className="titleCard">
                        <h2 className="style.h2">Welcome to the Chooter prototype!</h2>
                        </Card>
                        <div className="landing">
                        <img className="img" alt="large Chooter logo" src = "https://chooter-images.s3.eu-west-2.amazonaws.com/Chooter_large_graphic.png"/>
                        <Paper className="paper" zDepth={2}>
                        <div className="landingText">
                        <h3 className="h3Black">Your personal tutor</h3>
                        <h3 className="h3Orange">Anytime</h3>
                        <h3 className="h3Black">Everywhere</h3>
                        <br/>
                        <p><a href={'/#/signup'}>Sign up now</a> to get access to the latest tools and lessons
                        and check back often to see newly added content and features</p>
                        <p>Good luck and enjoy!</p>
                        <br/>
                        <h5>The Chooter team</h5>
                        <br/>
                        <Button className="button" primary={true} alignSelf={'left'} hoverIndicator={true} icon = <Play color = 'white'/> href={'/#/signup'} label={'Sign Up Now'}/>
                        </div>
                        <br/>
                        </Paper>
                        </div>
                    </div>
            </div>
        )}}
