import React from 'react';
import {Meter} from 'grommet';
import LegoAnimation from "../LegoAnimation";
import axios from "axios";
import _ from "lodash"
import { FaStar } from 'react-icons/fa'
//import { connect } from 'react-redux'


class UserStats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profile:null,
            profileGreeting:null,
            loading: true,
            lessonTotal: 0
        };
        this.getUserLessonData = this.getUserLessonData.bind(this)
    }

  getUserLessonData(){

      const profile = this.props.profile;
      let lessonAddition = 0; //Create Object to add to when looping throgh DB: avoids 'this' undefined issue within lodash function
      let lessonDataArray = [];
      axios.get(`/getUserLessonData`, {
          params: {
              cognitoUUID: profile.cognitoUUID
          }
      }).then(res => {
          if (res.data) {
              _.forEach(res.data, function (option) {
                  lessonDataArray.push(option)
              })}
          _.forEach(lessonDataArray.slice(3,lessonDataArray.length-2), function (option) {
              lessonAddition += option
          });
      }).then(() => this.setState({lessonTotal: lessonAddition, loading:false}));
  }

  render() {
      const {active} = false;
      const {lessonTotal} = this.state;
      const profile = this.props.profile;
      const profileGreeting = this.props.profileGreeting;

      if(this.state.loading) {
          this.getUserLessonData()
              }




    if(this.state.loading){
        return (<div id="user-score" className="card">
                <div class = "justify-content-center align-items-center">
                    <h3>Fetching your data...</h3>
                    <LegoAnimation/>
                </div>
                </div>
        )
    }else{
    return (
      <div id="user-score" className="card">
        <div className="stats-container">
            <h1>Welcome {profile.name}!</h1>
            <br/>
            <p>Looks like you're {profileGreeting}</p>
            <br/>
            <p>Here's how you're getting on:</p>
            <br/>
          <div className="stats">
              <h3 className="stats-text">Critical Thinking: </h3>
            <h3 className="stats-text">{profile.scoreB}/1000</h3>
          </div>
            <Meter
                type = 'bar'
                alignSelf = 'center'
                round = {true}
                thickness = 'medium'
                values = {[
                    {
                        label: 'First',
                        value: 1 + profile.scoreB,
                        highlight: (active === 'First'),
                        color: '#F07F0E',
                        onHover: over => this.setState({ active: over ? 'First' : undefined})

                    },
                    {
                        value: (1000 - profile.scoreB),
                        color: '#18455E',
                    }
                ]}
            />
            <br/>
          <div className="stats">

              <h3 className="stats-text">Problem Solving: </h3>
              <h3 className="stats-text">{profile.scoreC}/1000</h3>
          </div>
            <Meter
                type = 'bar'
                alignSelf = 'center'
                round = {true}
                thickness = 'medium'
                values = {[
                    {
                        label: 'First',
                        value: 1 + profile.scoreC,
                        highlight: (active === 'First'),
                        color: '#F07F0E',
                        onHover: over => this.setState({ active: over ? 'First' : undefined})

                    },
                    {
                        value: (1000 - profile.scoreC),
                        color: '#18455E',
                    }
                ]}
            />
            <br/>
          <div className="stats">
              <h3 className="stats-text">Maths Skills: </h3>
              <h3 className="stats-text">{profile.scoreA}/1000</h3>
          </div>
            <Meter
                type = 'bar'
                alignSelf = 'center'
                round = {true}
                thickness = 'medium'
                values = {[
                    {
                        label: 'First',
                        value: 1 + profile.scoreA,
                        highlight: (active === 'First'),
                        color: '#F07F0E',
                        onHover: over => this.setState({ active: over ? 'First' : undefined})

                    },
                    {
                        value: (1000 - profile.scoreA),
                        color: '#18455E',
                    }
                ]}
            />
          <div className="stats" style={{marginTop: '1vh'}}>
              <FaStar
                  color = {'gold'}
                  style = {{margin: '1vh'}}
                  size = {'5vh'}/>
              <h3 className="stats-text">Lessons Completed: </h3>
              <h3 className="stats-text">{lessonTotal}</h3>
          </div>
        </div>
      </div>
    );
  }}
}

export default UserStats
