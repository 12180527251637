import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from '../Lotties/animation-w600-h300'

class BrokenTwigAnimation extends Component {


    render(){

        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return(
            <Lottie options={defaultOptions}
                    height={150}
                    width={300}
            />
        )
    }
}

export default BrokenTwigAnimation