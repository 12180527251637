import * as Phaser from "phaser";

import spike from "../../assets/images/spike.png"
import background from "../../assets/images/blue.jpg";
import {bubbleColorsArray} from "./bubbleColors";
import {GAME_TIME} from "../../containers/MathGameContainer";
import axios from "axios";

axios.defaults.baseURL = 'https://chooter.herokuapp.com';

const SPIKE_WIDTH = 50;
const SPIKE_HEIGHT = 45;
const BUBBLE_SIZE = 100;

class MathGame extends Phaser.Scene {

  bubbles;
  spikes;
  bubbleArray;
  interval;
  onUpdateBubbleArray;
  onUpdateBubbleArraySize;

  constructor() {
    super({key: 'MathGame'})
  }

  clearBubblesInterval() {
    clearInterval(this.interval);
  }

  preload() {
    this.clearBubblesInterval();

    this.game.react.setState({lives: 1, score: 0, countdownTime: '', startTime: '', hasGameEnded: false}, () => {
      let now = Date.now();
      this.game.react.setState({startTime: now, countdownTime: now + GAME_TIME})
    });

    this.load.crossOrigin = true;
    this.load.image('background', background);
    this.load.image('spike', spike);
    this.events.on('gameDifficultyChange', this.handleBubbleEmit, this);
    this.events.on('gameClearInterval', () => this.clearBubblesInterval(), this);
    this.bubbleArray = this.add.group();
  }

  create() {
    this.prepareGameElements();
    this.renderBubble();
    this.addCollider();
    this.startEmitting();
  }

  update() {
    this.onUpdateBubbleArray = this.bubbles.children.getArray();
    if (this.onUpdateBubbleArray.length > 0 && this.onUpdateBubbleArray.length !== this.onUpdateBubbleArraySize) {
      let lowestBubble = this.onUpdateBubbleArray.reduce((acc, curr) => {
        return acc.y > curr.y ? acc : curr
      });
      this.game.react.setCurrentBubble(lowestBubble);
    }
    this.onUpdateBubbleArraySize = this.onUpdateBubbleArray.length;
  }

  handleBubbleEmit() {
    this.clearBubblesInterval();
    this.interval = setInterval(() => {
      this.renderBubble();
    }, this.game.react.state.bubbleInterval);
    this.addCollider();
  }

  startEmitting() {
    this.events.emit('gameDifficultyChange');
    this.game.react.countdownRef.start();
  }

  prepareGameElements() {
    this.physics.world.checkCollision.up = false;
    this.add.image(0, 0, 'background').setOrigin(0).setDisplaySize(this.game.config.width, this.game.config.height);
    this.bubbles = this.add.group();
    this.spikes = this.physics.add.staticGroup({
      key: 'spike',
      repeat: Math.round(this.cameras.main.width / SPIKE_WIDTH),
      setXY: {x: 30, y: this.cameras.main.height - SPIKE_HEIGHT, stepX: 50},
      setScale: {x: 0.2, y: 0.2}
    });
  }

  addCollider() {
    this.physics.add.collider(this.bubbles, this.spikes, (bubble, spike) => {
      bubble.destroy();
      this.game.react.setState((prevState) => ({lives: prevState.lives - 1}));
        axios.post(`/addUserBubblesAnswer`,{
            "questionId": this.game.react.state.equationObject.uuid,
            "correct": false,
            "popped": true,
            "answered": false});
    });
  }

  renderBubble() {
    const {bubbleXVelocity, bubbleYVelocity, currentEquationsArray, usedQuestionsArray} = this.game.react.state;
    let randomEquation = currentEquationsArray[Math.floor(Math.random() * currentEquationsArray.length)];
    while (usedQuestionsArray.indexOf(randomEquation)>-1){

        randomEquation = currentEquationsArray[Math.floor(Math.random() * currentEquationsArray.length)];
    }
    //push used question to usedQuestionsArray after used question is finalised
    usedQuestionsArray.push(randomEquation);
    let randomX = Math.floor(Math.random() * ((this.game.config.width - BUBBLE_SIZE) - (BUBBLE_SIZE + 1))) + BUBBLE_SIZE;
    let circle = this.createBubble(randomX, -BUBBLE_SIZE, BUBBLE_SIZE, BUBBLE_SIZE, randomEquation, bubbleXVelocity, bubbleYVelocity);
    this.bubbles.add(circle);
  }

  createBubble(x, y, width, height, randomEquation, bubbleXVelocity, bubbleYVelocity) {
    let bubbleWidth = width;
    let bubbleHeight = height;
    let bubblePadding = 10;
    let bubble = this.make.graphics({x: 0, y: 0, add: false});
    bubble.fillStyle(0x222222, 0.2);
    bubble.fillEllipse(3, 3, bubbleWidth, bubbleHeight, 100);
    bubble.fillStyle(bubbleColorsArray[Math.floor(Math.random() * bubbleColorsArray.length)], 1);
    bubble.fillEllipse(0, 0, bubbleWidth, bubbleHeight, 100);
    let content = this.make.text(0, 0, '');
    content.text = randomEquation.text;
    content.setStyle({
      fontFamily: 'Arial',
      fontSize: 20,
      color: '#000000',
      align: 'center',
      wordWrap: {width: bubbleWidth - (bubblePadding * 2)}
    });
    let textBounds = content.getBounds();
    content.setPosition(bubble.x - (textBounds.width / 2), bubble.y - (textBounds.height / 2));
    let bubbleContainer = this.add.container(x, y, [bubble, content]);
    bubbleContainer.setSize(width, height);
    this.physics.world.enable(bubbleContainer);
    bubbleContainer.body.setVelocity(bubbleXVelocity, bubbleYVelocity);
    bubbleContainer.body.setCollideWorldBounds(true);
    bubbleContainer['currentBubbleStartTime'] = Date.now();
    bubbleContainer['currentBubbleID'] = randomEquation.id;
    return bubbleContainer;
  }
}

export default MathGame
