import React, {createRef} from "react";
import _ from 'lodash';
import LoadingScreen from "../../components/LoadingScreen"
import QuestionContainer from "../QuestionContainer";
import WorkedExampleStarRating from "../../components/WorkedExampleStars"

import axios from "axios";
//import validate from "validate.js";
import {Box, Button, Drop} from "grommet/es6";
import Question from "../../gameComponents/Question";
import {Close} from "grommet-icons/es6";

const TSA2017 = [132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157,158,159,160,161,162,163,164,165,166,167,168,169,170,171,172,173,174,175,176,177,178,179,180,181];
const TSA2018 = [182,183,184,185,186,187,188,189,190,191,192,193,194,195,196,197,198,199,200,201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,219,220,221,222,223,224,225,226,227,228,229,230,231];


const paperMenu = [
    {title: "TSA 2017", array: TSA2017, type: "TSA", qNumber: 50, img: "https://chooter-images.s3.eu-west-2.amazonaws.com/2017icon.png", alt: "2017 icon"},
    {title: "TSA 2018", array: TSA2018, type: "TSA", qNumber: 50, img: "https://chooter-images.s3.eu-west-2.amazonaws.com/2018icon.png", alt: "2018 icon"}

];

axios.defaults.baseURL = 'https://chooter.herokuapp.com';

class WorkedExampleContainer extends React.Component {

    countdownRef;

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            isMultiSelect: true,
            questionNumber: 0,
            workedAnswerObject: null,
            isOuterMenuVisible: true,
            isMenuVisible: true,
            isGameEnded: false,
            answerReview: false,
            isQuestionVisible: false,
            questionAnswerCorrect: null,
            loading: false,
            numberButtons: [],
            openDrop: false,
            workedExampleUpdated: false
        };
        this.handleEnterKey = this.handleEnterKey.bind(this);
        this.submitAndMoveToWorkedAnswer = this.submitAndMoveToWorkedAnswer.bind(this);
        this.onOpenDrop = this.onOpenDrop.bind(this);
        this.onCloseDrop = this.onCloseDrop.bind(this);
    }

    buttonRef = createRef();

    componentDidMount() {
        document.addEventListener('keyup', this.handleEnterKey);
        if(this.props.profile.scoreB === 0){
            this.setState({initialAssessment: true})
        }

    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleEnterKey);
    }

    submitResult() {
        const {answersArray, gameTime} = this.state;
        let correctAnswers = _.sumBy(
            answersArray,
            (a) => {
                return a.isAnswerCorrect === true
            }
        );

        return {correctAnswers, answersArray, gameTime};
    }

    parentGetAnswers(childGetAnswers) {
        this.childGetAnswers = childGetAnswers;
    }

    validateAnswer(answersArray, currentQuestion) {
        let correctAnswers = [];
        _.forEach(currentQuestion.AnswerOptions, function(option){
            if(option.correct){
                correctAnswers.push(option.text);}
        });

        return _.isEqual(_.sortBy(answersArray), _.sortBy(correctAnswers));
    }


    handleEnterKey(e) {
        const {isQuestionVisible, isMenuVisible} = this.state;
        if (isQuestionVisible && !isMenuVisible && e.key === 'Enter') {
            this.submitAndMoveToWorkedAnswer();
        }
    }


     submitAndMoveToWorkedAnswer() {


         const {workedAnswerObject} = this.state;
            let {answersArray} = this.childGetAnswers();
            let isAnswerCorrect = this.validateAnswer(answersArray, workedAnswerObject);


            this.setState({
                questionAnswerCorrect: isAnswerCorrect,
                isQuestionVisible: false,
                isWorkedAnswerVisible: true
            })

    }


    assignNumberMenu(array){
        let numberButtons = array.map((object, i) =>
            <Button
            label = {i+1}
            className = "button"
            key = {i}
            onClick = {() => this.fetchWorkedAnswer(object)}/>
            );
        console.log(numberButtons);
        this.setState({numberButtons: <div className = "numberMenuWrapper">
            <Button label = "BACK" className = "backButton" onClick={() => this.backToPaperMenu()}/>
            <div className = "numberMenu">
                {numberButtons}
            </div>
        </div>, isOuterMenuVisible: false})
    }

    renderQuestionContainer(currentQuestion, isMultiSelect) {
        return (
            <QuestionContainer isMultiSelect={isMultiSelect}
                               currentQuestion={currentQuestion}
                               shareMethod={this.parentGetAnswers.bind(this)}
            />
        )
    }

    backToNumberMenu(){
        this.setState({
            workedAnswerObject: null,
            isQuestionVisible: false,
            isMenuVisible: true,
            workedExampleUpdated: false
        })
    }

    backToPaperMenu(){
        this.setState({
            isOuterMenuVisible: true,
            numberButtons: null
        })
    }

    fetchWorkedAnswer(questionNumber) {

       this.setState({loading:true});

        axios.get(`/getWorkedExampleQuestion`, {
            params: {
                questionNumber: questionNumber
            }
        }).then(res => {

            this.setState({
                workedAnswerObject: res.data[0],
                loading: false,
                isQuestionVisible: true,
                isMenuVisible: false
            })
        });
    }

    renderWorkedAnswerQuestion(){
        let isMultiSelect = false;
        const {workedAnswerObject, workedExampleUpdated} = this.state;

        if(!workedExampleUpdated){
            this.setState({workedExampleUpdated: true});
            axios.post('/updateWorkedExample',{
                uuid: workedAnswerObject.WorkedExamples[0].uuid,
                served: true
            })}

        return (
            <div className="workedAnswerQuestionWrapper">
                <Button className="backButton" label = "BACK" onClick={() => this.backToNumberMenu()}/>
                <div className="gamePlayWrapperInner">
                        {this.renderQuestionContainer(workedAnswerObject, isMultiSelect)}
                </div>
                <Button className="submitButton" onClick={this.submitAndMoveToWorkedAnswer}
                >SUBMIT</Button>
            </div>
        )
    }

    onOpenDrop = () => {
        this.setState({openDrop: true});
    };


    onCloseDrop = () => {
        this.setState({openDrop: false});
    };


    renderWorkedAnswer(){
        const {workedAnswerObject, questionAnswerCorrect, openDrop} = this.state;

        let correctAnswer = [];
        let correctAnswerImage = [];

        workedAnswerObject.AnswerOptions.forEach(answer => {if(answer.correct)
        {correctAnswer.push(answer.text);
         correctAnswerImage.push(answer.image)}});

        let answerOptions = workedAnswerObject.AnswerOptions.map((answer, index) => {
            if(correctAnswerImage[0].length > 0){
                if(answer.correct){
                    return <Box index = {index} className= "answerBox" background={'lightgreen'}><img className= "image" src = {answer.image} alt={'correct answer'}/></Box>}
                     else {return <Box index = {index} className= "answerBox" background={'red'}><img className= "image" src = {answer.image} alt={'wrong answer'}/></Box>}
                }else{
                if(answer.correct){
                return <Box index = {index} className= "answerBox" background={'lightgreen'}>{answer.text}</Box>}
        else {return <Box index = {index} className= "answerBox" background={'red'}>{answer.text}</Box>}
            }}
            );


        return (
            <div className="workedAnswerWrapper">
                <Button label = "BACK" onClick={() => this.backToNumberMenu()} className= "backButton"/>
                {questionAnswerCorrect ? (
                    <Box className= "correctAnswerBox" background={'lightgreen'}>{'Correct!'}</Box>
                ) : ( correctAnswerImage[0].length > 0 ? (<Box className= "correctAnswerBox" background={'red'}>{`Oops, not quite - the answer was:`}
                <img src={correctAnswerImage[0]} style = {{maxWidth: '90%', height: 'auto'}} alt={'correct answer'}/></Box>)
                    :(<Box className= "correctAnswerBox" background={'red'}>{`Oops, not quite - the answer was '${correctAnswer}'`}</Box>))
                }
                <h3>Explanation:</h3>
                {workedAnswerObject.WorkedExamples[0].workedAnswerImage.length>0 ? (
                    <div>
                    <Box>{workedAnswerObject.WorkedExamples[0].workedAnswer}</Box>
                    <Box className= "workedAnswerImageBox"><img src={workedAnswerObject.WorkedExamples[0].workedAnswerImage} style = {{maxWidth: '90%', height: 'auto'}} alt={'worked answer'}/></Box>
                    </div>
                ) : ( <Box>{workedAnswerObject.WorkedExamples[0].workedAnswer}</Box>)
                }
                {!openDrop ? (<Button ref = {this.buttonRef} className='reminderTitle' label= "Remind me of the question" onClick = {this.onOpenDrop}/>):
                    (<Button ref = {this.buttonRef} className='reminderTitle' label= "Collapse" onClick = {this.onCloseDrop}/>)}
                {
                    openDrop && (
                <Drop responsive = {true} align = {{bottom: 'top', center:"center"}} className="workedAnswerWrapper" target = {this.buttonRef.current} onClickOutside= {this.onCloseDrop}>
                    <div className="workedAnswerWrapperInner">
                    <Question id={workedAnswerObject.id}
                              intro={workedAnswerObject.intro}
                              statement={workedAnswerObject.statement}
                              image={workedAnswerObject.image}
                    />
                    {answerOptions}
                </div>
                </Drop>
                )}
                <WorkedExampleStarRating
                    workedExampleID = {workedAnswerObject.WorkedExamples[0].id}
                    auth = {this.props.auth}
                />
            </div>
        )
    }

    renderMenuDiv() {

        const {isOuterMenuVisible, numberButtons, disable} = this.state;

        let paperMenuButtons = paperMenu.map((obj, index) =>

            <div className="level-selector" key ={index}>
                <div
                    className={
                        disable === false
                            ? 'icon-background-circle'
                            : 'disabled-circle'
                    }
                >

                    <Button
                        onClick={ () => this.assignNumberMenu(obj.array)}>
                        <img
                            className="game-icon"
                            src={obj.img}
                            alt={obj.alt}
                        />
                    </Button>
                </div>
                <Button
                    onClick={ () => this.assignNumberMenu(obj.array)}
                    //className={disable === false ? '' : 'disabled-link'}
                >
                    <h3>{obj.title}</h3>
                </Button>
            </div>);

        if(isOuterMenuVisible){
            return(
                <div className = "paperMenu" >
                <Button
                    icon = {<Close/>}
                    onClick={this.props.end}
                    label={'Close'}
                    style = {{background: "white", marginTop: '4vh', marginBottom: '1vh'}}/>
                <div id="skill-tree-paperMenu" className="card">
                {paperMenuButtons}
                </div>
                </div>
                )
        }else{
        return (
            <div>
                {numberButtons}
            </div>
        )}
    }


    renderGameContainer() {
        const {isQuestionVisible, isMenuVisible, isWorkedAnswerVisible, loading} = this.state;
        if(loading){
            return <LoadingScreen/>
        }
        if (isMenuVisible) {
            return this.renderMenuDiv();
        }

        if (isQuestionVisible) {
            return this.renderWorkedAnswerQuestion();
        }

        if(isWorkedAnswerVisible){
            return this.renderWorkedAnswer();
        }
    }

    render() {
        return (
            <div className="gameWrapper">
                {this.renderGameContainer()}
            </div>
        )
    }
}

export default WorkedExampleContainer
