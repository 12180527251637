import React, { Component } from 'react';
import { SidePanel } from '../Home/SidePanel';
import axios from "axios";
import UserStats from '../Home/UserStats';
import NewProfileForm from "../../components/NewProfileForm";
import LoadingScreen from "../../components/LoadingScreen"
//import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import MainMenu from "../../containers/MainMenu";
import StatementsGameContainer from "../../containers/StatementsGameContainer";
import BubblesGameOuterShell from "../../containers/BubblesGameOuterShell";
import ProblemSolvingGameContainer from "../../containers/ProblemSolvingGameContainer";
import LessonSlidesContainer from "../../containers/LessonSlidesContainer/LessonContainer";
import LessonMenu from "../../containers/LessonMenu";
import WorkedExampleContainer from "../../containers/WorkedExampleContainer";

axios.defaults.baseURL = 'https://chooter.herokuapp.com';

class ProfilePage extends Component {

    static propTypes = {
        auth: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {
            lessonID:0,
            loading: true,
            profile: [],
            newProfile: false,
            UserStatsBox: null,
            dataMissing: false,
            bottomPhrase: null,
            profileFetched: false,
            bottomButton: null,
            requestSent: false,
            lessonAxiosCall: null,
            lessonTitle: null,
            profileGreeting: '',
            userLessonData: [],
            disable: true,
            isBubblesGameVisible: false,
            isCriticalThinkingGameVisible: false,
            isProblemSolvingGameVisible: false,
            isLessonContainerVisible: false,
            isLessonMenuVisible: false,
            isWorkedExampleContainerVisible: false
        };
        this.endAll = this.endAll.bind(this);
        this.menuSetState = this.menuSetState.bind(this);
        this.startNewLesson = this.startNewLesson.bind(this);
        this.setLesson = this.setLesson.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.newProfile = this.newProfile.bind(this);
        this.getUser = this.getUser.bind(this);


        //this.handleClick = this.handleClick.bind(this);
        //this.handleSkip = this.handleSkip.bind(this);
    }

    getUser() {
        //const {newProfile, profileGreeting, profile} = this.state;
        this.setState({requestSent: true});
        const auth = this.props.authObject;
            axios.get(`/getUserFromUUID`, { params:{
                    cognitoUUID: auth.info.idToken.payload.sub
                }}).then(res => {
                if(res.data){
                    if(res.data.scoreA === 0 || res.data.scoreB === 0){
                        this.setState({profile: res.data, profileFetched: true, dataMissing: true, loading: false, disable: false})
                    }else{
                        this.setState({profile: res.data, profileFetched: true, loading: false, disable: false})}
                }else{axios.get(`/getUser`, { params:{
                        emailAddress: auth.info.username}
                }).then(res => {
                    if(res.data){
                        axios.post(`/addX`, {
                            email: auth.info.username,
                            cognitoUUID: auth.info.idToken.payload.sub
                        });
                        if(res.data.scoreA === 0 || res.data.scoreB === 0){
                            this.setState({profile: res.data, profileFetched: true, dataMissing: true, loading: false, disable: false})
                        }else{
                            this.setState({profile: res.data, profileFetched: true, loading: false, disable: false})}
                    }else{
                        this.setState({newProfile: true, loading: false})
                    }
                })}
        })}

    refreshProfilePage(){
        const auth = this.props.authObject;

        const {profile, profileGreeting, userLessonData} = this.state;
        console.log(userLessonData);
        axios.get(`/getUser`, { params:{
                emailAddress: auth.info.username}
        }).then(res => {
            if (res.data) {
                this.setState({profile: res.data, profileFetched: true, disable: false});
                this.setState({UserStatsBox: <UserStats
                        profile = {profile}
                        profileGreeting = {profileGreeting}
                        userLessonData = {userLessonData}
                    />});
                this.setState({loading: false})
            }
        } )
    }

    newProfile(i, j){
        let {profileGreeting,profile} = this.state;
        let newProfileGreeting = 'just getting started';
        let newProfile = {role: i,
            name: j,
            scoreA: 0,
            scoreB: 0};

        this.setState({profile: newProfile,
            UserStatsBox: null, profileGreeting: '', profileFetched: true, dataMissing: true, loading: false, disable: false
        });

        if(profileGreeting==='' && profile.role){
            if(i === "TEST"){
                this.setState({profileGreeting: 'just testing Chooter out right now'});
                 newProfileGreeting = 'just testing Chooter out right now'
            }else{
                this.setState({profileGreeting: `preparing for the ${i} right now. Good luck!`});
                 newProfileGreeting = `preparing for the ${i} right now. Good luck!`
            }}

        this.setState({UserStatsBox: <UserStats
                profile = {newProfile}
                profileGreeting = {newProfileGreeting}
            />})
    }

    endAll(){
        this.setState({
            isLessonContainerVisible: false,
            isProblemSolvingGameVisible: false,
            isCriticalThinkingGameVisible: false,
            isBubblesGameVisible: false,
            isWorkedExampleContainerVisible: false,
            lessonAxiosCall: null,
            lessonTitle: null,
            loading: true});

        this.refreshProfilePage();
    }

    closeMenu(){
        this.setState({isLessonMenuVisible: false})
    }

    startNewLesson(){
       const {lessonID, lessonAxiosCall, lessonTitle} = this.state;
        return(<LessonSlidesContainer
            lessonID = {lessonID}
            axiosCall = {lessonAxiosCall}
            lessonTitle = {lessonTitle}
            end = {() => this.endAll}
            auth = {this.props.authObject}
        />)
        }

    setLesson(i, axiosCall, lessonTitle){
        console.log(lessonTitle, axiosCall);
        this.setState({lessonID: i, lessonTitle: lessonTitle, lessonAxiosCall: axiosCall, isLessonContainerVisible: true})
    }

    menuSetState(stateID){

        if (stateID === 1){
            this.setState({isBubblesGameVisible: true})
        }
        else if (stateID === 2){
            this.setState({isCriticalThinkingGameVisible: true})
        }
        else if (stateID === 3){
            this.setState({isProblemSolvingGameVisible: true})
        }
        else if (stateID === 4){
            this.setState({isLessonMenuVisible: true})
        }
        else if (stateID === 5){
            this.setState({isWorkedExampleContainerVisible: true})
        }
    }

    render() {
        const {loading, disable, newProfile, profileGreeting, profile, UserStatsBox, requestSent, initialAssessmentStatement,
            isCriticalThinkingGameVisible, isLessonMenuVisible, isLessonContainerVisible, isBubblesGameVisible, isProblemSolvingGameVisible,
            isWorkedExampleContainerVisible}  = this.state;

        if(profileGreeting==='' && profile.role){
            if(profile.role === "TEST"){
                this.setState({profileGreeting: 'just testing Chooter out right now'})
            }else if(profileGreeting===''){
                this.setState({profileGreeting: `preparing for the ${profile.role} right now. Good luck!`})
            }}

        if(UserStatsBox === null && profile.name && profileGreeting !== ''
        ){
            this.setState({UserStatsBox: <UserStats
                    profile = {profile}
                    profileGreeting = {profileGreeting}
                />})
        }

        if(UserStatsBox === null && newProfile){
                this.setState({UserStatsBox:
                        <NewProfileForm
                        auth = {this.props.authObject}
                        onSubmit={this.newProfile}/>})
        }

        if(loading || UserStatsBox === null){
            if(!requestSent){
            this.getUser()}

            return <LoadingScreen/>

        }

        if(isBubblesGameVisible){
            return(
            <BubblesGameOuterShell
                profile = {this.state.profile}
                end = {this.endAll}
            />)
        }

        if(isWorkedExampleContainerVisible){
            return(
                <WorkedExampleContainer
                    profile = {this.state.profile}
                    end = {this.endAll}
                    auth = {this.props.authObject}
                />
            )
        }

        if(isCriticalThinkingGameVisible){
        if(initialAssessmentStatement){
            return (<div>
                    <StatementsGameContainer
                        profile = {this.state.profile}
                        onFinish = {this.endAll}
                        auth = {this.props.authObject}
                    />
                </div>
            )} else {
            return (<div>
                <StatementsGameContainer
                    profile = {this.state.profile}
                    onFinish = {this.endAll}
                />
            </div>)
        }}

        if(isLessonContainerVisible){
            return(
                <div>
                    {this.startNewLesson()}
                </div>)
        }

        if(isLessonMenuVisible){
            return <div className="ProfilePage">
                <div className="home">
                    <div id="side-panel">
                        <SidePanel
                            Statsbox = {this.state.UserStatsBox} />
                    </div>
                    <LessonMenu
                    close = {() => this.closeMenu}
                    onClick = {(i, axiosCall, lessonTitle) => this.setLesson(i, axiosCall, lessonTitle)}
                    auth = {this.props.authObject}
                    />
                </div>
            </div>
        }

        if(isProblemSolvingGameVisible){
            return <ProblemSolvingGameContainer
                profile = {this.state.profile}
                onFinish = {this.endAll}
                auth = {this.props.authObject}
            />
        }

            return (
            <div className="ProfilePage">
            <div className="home">
                <div id="side-panel">
                <SidePanel
                    Statsbox = {this.state.UserStatsBox} />
            </div>
            <MainMenu onClick = {this.menuSetState}
                disable = {disable}/>
            </div>
            </div>
        );
    }
}


export default ProfilePage