import React from 'react';
//import UserStats from './UserStats';
import Leaderboard from "./Leaderboard";

export class SidePanel extends React.Component {
    render() {
        const Statsbox = this.props.Statsbox;

        return (
            <div id="sidebar">
                {Statsbox}
                <Leaderboard/>
            </div>
        );
    }
};
