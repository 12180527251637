import React from "react";
import {BarLoader} from 'react-css-loaders';

const quotes = [`"It always seems impossible, until it is done." - Nelson Mandela`, `"The only thing that interferes with my learning is my education" - Albert Einstein`, `"There are no limits to what you can accomplish, except the limits you place on your own thinking" - Brian Tracy`, `"If you can't fly then run. If you can't run then walk. If you can't walk then crawl, but whatever you do... keep moving forward" - Martin Luther King Jr.`, `"Our greatest glory is not in never falling, but in rising every time we fall." - Confucius`, `"Whatever goal you give to your subconscious mind, it will work night and day to achieve" - Jack Canfield`, `"Believe you can and you're halfway there." - Theodore Roosevelt`, `"You don't need a new day to start over, you only need a new mindset." - Hazel Hira Ozbek`];

let quote = quotes[Math.floor(Math.random()*quotes.length)-1];

export const LoadingScreen = () =>

    <div className="startGameWrapper">
    <br/>
    <h2>Loading...</h2>
    <BarLoader color = '#194560' />
    <br/>
    <h3><i>{quote}</i></h3>
    <br/>
    <p>If you're stuck here for more than 10 seconds please refresh the page</p>
        <br/>
    <p>Thank you for testing!</p>
    <br/>
    </div>;

export default LoadingScreen
