import React from 'react'
import PropTypes from 'prop-types'

import {ToolbarGroup} from 'material-ui/Toolbar'
import ChooterBar from '../ChooterBar'
import {Button, Menu} from 'grommet'
import FlatButton from 'material-ui/FlatButton'

import { Link } from 'react-router-dom'

import { state } from 'aws-cognito-redux-saga'

export default class HeaderComponent extends React.Component {
  static propTypes = {
    isSignedIn: PropTypes.string,
    signUpError: PropTypes.bool,
    signOut: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    auth: PropTypes.object
  };

  signOut = () => {
    this.props.signOut()
  };

  render() {
    const { auth } = this.props;

    return (
      <div>
        <ChooterBar color = 'white'>
          <ToolbarGroup backgroundColor = 'white'>
            <Button
                style = {{marginTop: '30%'}}
              href="/"
            >
              <img alt="Chooter" width="40" src="https://chooter.co.uk/wp-content/uploads/2019/01/Primary-Logo-colour-1.png" />
            </Button>

          </ToolbarGroup>

          <ToolbarGroup backgroundColor = 'white'>
            {auth.isSignedIn !== state.AUTH_SUCCESS ? (
              <FlatButton
                containerElement={<Link to="/signin" />}
                label="Sign Up / Sign In"
                onClick={this.signIn}
              />
            ) : (
              <div>
                {auth.info.username}
                <Menu

                  label = "Menu"
                  items={[
                      {label:"Sign Out", onClick:() => {this.signOut()}}
                              ]}/>
              </div>
            )}
          </ToolbarGroup>
        </ChooterBar>
      </div>
    )
  }
}
