import React from 'react'
import PropTypes from 'prop-types'

import { state } from 'aws-cognito-redux-saga'
import ProfilePage from "../ProfilePage";


export class ProtectedComponent extends React.Component {
  static propTypes = {
    isSignedIn: PropTypes.string,
    auth: PropTypes.object
  };

  /*renderAuthInfo(auth) {
    return (
      <div style={style.token}>
          <div>{auth.info.username}</div>
        <div style={style.label}>Access Token</div>
        <div>{auth.info.accessToken.jwtToken}</div>
        <div style={style.label}>ID Token</div>
        <div>{auth.info.idToken.jwtToken}</div>
        <div style={style.label}>Refresh Token</div>
        <div>{auth.info.refreshToken.token}</div>
      </div>
    )
  }*/

  render() {
    const { auth } = this.props;

    console.log(auth);

    return (<div>
          {auth.isSignedIn === state.AUTH_SUCCESS
            ? <ProfilePage
                  authObject = {auth}/>
            : null}
        </div>

    )
  }
}
