import React from 'react';
import PropTypes from 'prop-types';
import Question from "../../gameComponents/Question";
import Answer from "../../gameComponents/Answer";
import _ from 'lodash';

class QuestionContainer extends React.Component {
  state = {
    answersChecked: [],
    questionStartTime: 0
  };

  constructor(props) {
    super(props);
    this.changeEvent = this.changeEvent.bind(this);
  }

  componentDidMount() {
    this.props.shareMethod(this.getAnswers.bind(this));
    this.setState({questionStartTime: Date.now()})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentQuestion !== this.props.currentQuestion) {
      this.setState({answersChecked: [], questionStartTime: Date.now()})
    }
  }

  getAnswers() {
    const {answersChecked, questionStartTime} = this.state;
    let questionTimeTaken = Date.now() - questionStartTime;
    return {answersArray: answersChecked, questionTimeTaken}
  }

  renderQuestion() {
    const {currentQuestion} = this.props;
    return (
      <Question id={currentQuestion.id}
                intro={currentQuestion.intro}
                statement={currentQuestion.statement}
                image={currentQuestion.image}
      />
    )
  }

  renderAnswers() {
    const {currentQuestion} = this.props;
    const {answersChecked} = this.state;
      let answerOptions = [];
      _.forEach(currentQuestion.AnswerOptions, function(option){answerOptions.push(option.text)});
      return answerOptions.map((answer, index) => {
          let isChecked = answersChecked.includes(answer);
          return (
              <Answer id={index}
                      key={index}
                      answer={answer}
                      checked={isChecked}
                      onChange={this.changeEvent}
              />
      )
    })
  }

  changeEvent(event) {
    const {answersChecked} = this.state;
    const {isMultiSelect} = this.props;

    let checkedArray = answersChecked;
    let selectedValue = event.target.value;

    if (event.target.checked === true) {
      if (isMultiSelect) {
        checkedArray.push(selectedValue);
      } else {
        checkedArray = [selectedValue];
      }
      this.setState({answersChecked: checkedArray});
    } else {
      let valueIndex = checkedArray.indexOf(selectedValue);
      checkedArray.splice(valueIndex, 1);
      this.setState({answersChecked: checkedArray});
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="question">
          {this.renderQuestion()}
        </div>
        <div className="answersWrapper">
          {this.renderAnswers()}
        </div>
      </React.Fragment>
    )
  }
}

QuestionContainer.propTypes = {
  shareMethod: PropTypes.func,
  currentQuestion: PropTypes.instanceOf(Object)
};

export default QuestionContainer

