import React from "react";
import MathGameContainer from "../MathGameContainer";
import LoadingScreen from "../../components/LoadingScreen"
import axios from "axios";

axios.defaults.baseURL = 'https://chooter.herokuapp.com';

class BubblesGameOuterShell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            BubblesGameIntro: true,
            BubblesQuestions: null,
            loading: true
        };

    }

    componentDidMount(){
        if(this.state.BubblesQuestions === null){
            axios.get(`/getBubblesQuestions`)
                .then(res => {
                    this.setState({BubblesQuestions: res.data, loading: false});
                    console.log(this.state.BubblesQuestions)
                })}
    }

    render(){
const {BubblesGameIntro, loading} = this.state;

if (BubblesGameIntro){
    return(
        <div className="startGameWrapper">
            <h2>Welcome to the Maths prototype!</h2>
            <p>Once you click Start Game bubbles will start falling.</p>
            <p>Answer the question in the bottom bubble before it pops on the spikes. The difficulty and speed will increase over time.</p>
            <p><b>Submit an answer by typing it in and pressing enter on the keyboard</b></p>
            <p>or by using the keypad at the side of the screen.</p>
            <p>Click the button below when you're ready!</p>
            <button onClick={() => this.setState({BubblesGameIntro: false})}>LET'S GO</button>
            <button onClick={this.props.end}>BACK</button>
        </div>
    )
}
else if(loading){

    return (
        <LoadingScreen/>
    )

}else{
    return (<div>
        <MathGameContainer
            profile = {this.props.profile}
            onFinish = {this.props.end}
            questions = {this.state.BubblesQuestions}
        />
    </div>)
}
    }
}

export default BubblesGameOuterShell