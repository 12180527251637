import { connect } from 'react-redux'
import { LandingComponent } from './Landing'

const mapStatetoProps = state => {
  return {auth: state.auth}
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStatetoProps, mapDispatchToProps)(LandingComponent)
