import React, {Component} from "react";
import Countdown, {zeroPad} from 'react-countdown-now';
import PhaserGame from "../../gameComponents/PhaserGame";
import _ from "lodash";
import axios from 'axios';
import {clockIcon} from "../../components/ClockIcon";
import {lifeIcon} from "../../components/LifeIcon";
import {scoreIcon} from "../../components/ScoreIcon";
import {millisToMinutesAndSeconds} from "../../helpers";

axios.defaults.baseURL = 'https://chooter.herokuapp.com';

export const GAME_TIME = 420000;

const DIFFICULTY_LEVELS = 7;
const CLEAR_BUTTON_TEXT = "CLEAR";
const SUBMIT_BUTTON_TEXT = "SUBMIT";

const INITIAL_Y_VELOCITY = 80;
const INITIAL_X_VELOCITY = 2;
const INITIAL_INTERVAL = 3200;
const HALF_TIME_INTERVAL = 3500;

const resetState = {
  currentEquationsArray: [],
  usedQuestionsArray: [],
  hasGameEnded: true,
  lives: 1,
  currentEquation: "",
  score: 0,
  input: "",
  currentBubble: null,
  startTime: '',
  endTime: '',
  countdownTime: '',
  gameTime: '',
  solvedArray: [],
  bubbleXVelocity: INITIAL_X_VELOCITY,
  bubbleYVelocity: INITIAL_Y_VELOCITY,
  bubbleInterval: INITIAL_INTERVAL,
  isModalVisible: false,
  isIntroVisible: true,
  loading: false,
  equationObject: null
};

class MathGameContainer extends Component {

    constructor(props) {
        super(props);
        this.renderer = this.renderer.bind(this);
        this.endGame = this.endGame.bind(this);
    }

    state = {
        equationsArray: this.props.questions,
        ...resetState
    };

    difficultyFactor = 1;
    difficultyLevel = 1;
    difficultyIntervalObject = null;

    componentDidMount() {
        this.game = new PhaserGame(this);
        this.inputRef.focus();
        console.log(this.state.equationsArray[1].difficulty);
        this.setState({
            currentEquationsArray: _.filter(this.state.equationsArray, {'difficulty': this.difficultyLevel})
        });

    }

    handleEndGameResult() {
        //push to backend here
        let scoreToPush;

        let rawValueLow = Math.floor(1000*(Math.pow((0.0048*this.state.score-0.8),3) + 0.5));
        let rawValueHigh = Math.floor(1000*(Math.pow((0.0042*this.state.score-0.47),3) + 0.5));

            if(this.props.profile.scoreA === 0)
            {
            if(rawValueLow < 0){
            scoreToPush = 0
            } else if(rawValueLow < 500){
            scoreToPush = rawValueLow
            } else if (rawValueHigh < 1000){
            scoreToPush = rawValueHigh
            } else {
            scoreToPush = 1000
            }
            }

            else{
            if(rawValueLow < 0){
                scoreToPush = 0
            } else if(rawValueLow < 500){

                let newValue = this.props.profile.scoreA + (rawValueLow - this.props.profile.scoreA)/1.8;
                scoreToPush = newValue;
            } else if (rawValueHigh < 1000){

                let newValue = this.props.profile.scoreA + (rawValueHigh - this.props.profile.scoreA)/1.8;
                scoreToPush = newValue;
            } else {

                let newValue = this.props.profile.scoreA + (1000 - this.props.profile.scoreA)/1.8;
                scoreToPush = newValue;
            }

            }

            axios.post(`/updateUserScore`, {
                    email: this.props.profile.email,
                    scoreName: 'scoreA',
                    scoreValue: scoreToPush }
            )
    }

    resetGame() {
        this.difficultyFactor = 1;
        this.difficultyLevel = 1;
        this.setState({
            ...resetState,
            currentEquationsArray: _.filter(this.state.equationsArray, (e) => {
                return e.difficulty === this.difficultyLevel;
            }),
        })
    }

    handleUserInput(e) {
        this.setState({input: Number(e.target.value)});
    }

    handleNumPadInput(value) {
        let prevState = this.state.input.toString();
        let inputValue = value.toString();
        this.setState({input: prevState + inputValue});
    }

    gameDifficultyChange(bubbleXVelocity, bubbleYVelocity, bubbleInterval) {
        this.setState({
            bubbleXVelocity: bubbleXVelocity,
            bubbleYVelocity: bubbleYVelocity,
            bubbleInterval: bubbleInterval,
            usedQuestionsArray: []
        }, () => {
            this.game.scene.keys.MathGame.events.emit('gameDifficultyChange')
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.lives !== this.state.lives) {

            if (this.state.lives < 1) {
                this.endGame();
            }
        }
        if (prevState.countdownTime !== this.state.countdownTime) {
            this.startDifficultyInterval();
        }
        console.log(this.state.currentBubble)
    }

    startDifficultyInterval() {
        this.setState({difficultyIntervalTime: GAME_TIME / DIFFICULTY_LEVELS}, () => {
            this.difficultyIntervalObject = setInterval(() => {
                this.difficultyFactor += 0.06;
                if (this.difficultyLevel < DIFFICULTY_LEVELS) {
                    this.difficultyLevel += 1;
                }
                if (this.difficultyLevel === 3) {
                    this.setState({bubbleInterval: HALF_TIME_INTERVAL}, () => this.difficultyFactor = 1.09)
                }
                this.setState({
                    currentEquationsArray: _.filter(this.state.equationsArray, (e) => {
                        return e.difficulty === this.difficultyLevel;
                    })
                });
                let bubbleXVelocity = Math.floor(INITIAL_X_VELOCITY * this.difficultyFactor);
                let bubbleYVelocity = Math.floor(INITIAL_Y_VELOCITY * this.difficultyFactor);
                let bubbleInterval = Math.floor(this.state.bubbleInterval / this.difficultyFactor);
                this.gameDifficultyChange(bubbleXVelocity, bubbleYVelocity, bubbleInterval);
            }, this.state.difficultyIntervalTime)
        });
    }

    endGame() {
        this.setState({endTime: Date.now()}, () => {
            this.countdownRef.pause();
            this.game.scene.pause('MathGame');
            this.game.scene.start('GameOver');
            clearInterval(this.difficultyIntervalObject);
            this.setState({
                hasGameEnded: true,
                isModalVisible: true,
                gameTime: millisToMinutesAndSeconds(this.state.endTime - this.state.startTime)
            }, () => this.handleEndGameResult());
        });
    }


    handleCorrectAnswer(equationObject) {
        //const {currentBubble, solvedArray} = this.state;
        //let currentBubbleSolveTime = Date.now() - currentBubble.currentBubbleStartTime;
        this.setState(prevState => ({score: prevState.score + equationObject.difficulty, input: ""}));

        /*if (_.find(solvedArray, ['id', currentBubble.currentBubbleID])) {
            let eqIndex = _.findIndex(solvedArray, ['id', currentBubble.currentBubbleID]);
            if (solvedArray[eqIndex].solvedTimings) {
                this.setState({
                    solvedArray: solvedArray.map((el, index) => (index === eqIndex ? {
                        ...el,
                        timesTried: el.timesTried + 1,
                        timesCorrect: el.timesCorrect + 1,
                        solvedTimings: [...el.solvedTimings, currentBubbleSolveTime]
                    } : el))
                });
            } else {
                this.setState({
                    solvedArray: solvedArray.map((el, index) => (index === eqIndex ? {
                        ...el,
                        timesTried: el.timesTried + 1,
                        timesCorrect: el.timesCorrect + 1,
                        solvedTimings: [currentBubbleSolveTime]
                    } : el))
                });
            }
        } else {
            this.setState({
                solvedArray: [...solvedArray, {
                    ...equationObject,
                    timesTried: 1,
                    timesCorrect: 1,
                    solvedTimings: [currentBubbleSolveTime]
                }]
            });
        }*/

        axios.post(`/addUserBubblesAnswer`,{
            "questionId": equationObject.uuid,
            "correct": true,
            "popped": false,
            "answered": true});

        this.inputRef.focus();
        this.state.currentBubble.destroy();
    }

    handleWrongAnswer(equationObject) {
        //const {currentBubble, solvedArray} = this.state;
        this.setState({input: ""});
        /*if (_.find(solvedArray, ['id', currentBubble.currentBubbleID])) {
            let eqIndex = _.findIndex(solvedArray, ['id', currentBubble.currentBubbleID]);
            this.setState({
                solvedArray: solvedArray.map((el, index) => (index === eqIndex ? {
                    ...el,
                    timesTried: el.timesTried + 1
                } : el))
            });
        } else {
            this.setState({
                solvedArray: [...solvedArray, {
                    ...equationObject,
                    timesTried: 1,
                    timesCorrect: 0
                }]
            });
        }*/

        axios.post(`/addUserBubblesAnswer`,{
            "questionId": equationObject.uuid,
            "correct": false,
            "popped": false,
            "answered": true});

        this.inputRef.focus();
    }

    onResultSubmit() {
        const {equationsArray, currentBubble, input} = this.state;
        if (!this.state.hasGameEnded) {
            let equationObject = _.find(equationsArray, ['id', currentBubble.currentBubbleID]);
            let currentResult = Number(equationObject.simpleAnswer);

            if (currentResult === Number(input)) {
                this.handleCorrectAnswer(equationObject);
            } else {
                this.handleWrongAnswer(equationObject);
            }
        }
    }

    renderCountdown() {
        const {countdownTime} = this.state;
        if (countdownTime !== '') {
            return <Countdown
                ref={ref => {
                    this.countdownRef = ref;
                }}
                date={countdownTime}
                autoStart={false}
                precision={3}
                renderer={this.renderer}
                onComplete={this.endGame}
            />
        } else {
            return <span>00:00</span>
        }
    }

    renderer = ({hours, minutes, seconds, completed}) => {
        if (completed) {
            return <span>00:00</span>
        } else {
            return <span>{zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}</span>
        }
    };

    renderNumPad() {
        const {input} = this.state;

        return <div className="numPad">
            <div className="numPadInputWrapper">
                <input className="numPadInput" ref={ref => {
                    this.inputRef = ref;
                }} onKeyPress={(event) => {
                    if (event.key === "Enter") {
                        this.onResultSubmit()
                    }
                }} type="number" value={input} onChange={(e) => this.handleUserInput(e)}/>
            </div>
            <div className="numPadKeysWrapper">
                <div className="numPadKeysRow">
                    <div className="numPadKey" onClick={() => this.handleNumPadInput(1)}>1</div>
                    <div className="numPadKey middle" onClick={() => this.handleNumPadInput(2)}>2</div>
                    <div className="numPadKey" onClick={() => this.handleNumPadInput(3)}>3</div>
                </div>
                <div className="numPadKeysRow">
                    <div className="numPadKey" onClick={() => this.handleNumPadInput(4)}>4</div>
                    <div className="numPadKey middle" onClick={() => this.handleNumPadInput(5)}>5</div>
                    <div className="numPadKey" onClick={() => this.handleNumPadInput(6)}>6</div>
                </div>
                <div className="numPadKeysRow">
                    <div className="numPadKey" onClick={() => this.handleNumPadInput(7)}>7</div>
                    <div className="numPadKey middle" onClick={() => this.handleNumPadInput(8)}>8</div>
                    <div className="numPadKey" onClick={() => this.handleNumPadInput(9)}>9</div>
                </div>
                <div className="numPadKeysRow">
                    <div className="numPadKey zero" onClick={() => this.handleNumPadInput(0)}>0</div>
                    <div onClick={() => {
                        this.setState({input: ''});
                        this.inputRef.value = '';
                        this.inputRef.focus()
                    }} className="numPadKey clear">{CLEAR_BUTTON_TEXT}
                    </div>
                </div>
                <div onClick={() => this.onResultSubmit()} className="numPadKey submit">{SUBMIT_BUTTON_TEXT}</div>
            </div>
        </div>
    }

    setCurrentBubble(bubble) {
        const {currentBubble, equationsArray} = this.state;
        let equationObject = _.find(equationsArray, ['id', bubble.currentBubbleID]);
        if (currentBubble !== bubble) {
            this.setState({currentBubble: bubble, equationObject: equationObject});
        }
    }

    render() {
        console.log(this.state.currentEquationsArray);
        const {lives, score, hasGameEnded, isModalVisible, gameTime} = this.state;
            return <div className="gameWrapper">
                <div id="gameContainer"/>
                <div className="controls">
                    <div className="indicators">
                        <div className="countdown">
                            {clockIcon()} {this.renderCountdown()}
                        </div>
                        <div className="lives">
                            {lifeIcon()}<span>{lives}</span>
                        </div>
                        <div className="score">
                            {scoreIcon()}<span>{score}</span>
                        </div>
                    </div>
                    {this.renderNumPad()}
                </div>
                {hasGameEnded && isModalVisible ? <div className="resultModal">
                    <div className="close" onClick={() => this.setState({isModalVisible: false})}/>
                    <p>GAME OVER</p>
                    <div className="resultModalWrapper">
                        <div className="resultModalScore">
                            <div className="resultModalText">
                                <span>{clockIcon()}Game time</span><span>{gameTime}</span>
                            </div>
                            <div className="resultModalText">
                                <span>{lifeIcon()}Lives</span><span>{lives}</span>
                            </div>
                            <div className="resultModalText">
                                <span>{scoreIcon()}Score</span><span>{score}</span>
                            </div>
                            <button onClick={() => this.props.onFinish()}
                            > Finish
                            </button>
                        </div>
                    </div>
                </div> : null}
            </div>
        }
    }


export default MathGameContainer
