import { withStyles } from '@material-ui/core/styles';
import Toolbar from 'material-ui/Toolbar';

const ChooterBar = withStyles({
    root: {
        background: '#194560',
        color: 'white'

    }
})(Toolbar);

export default ChooterBar

