import React from "react";

export const clockIcon = () => <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
  <circle cx='256' cy='256' r='256' fill='#ff9e24'/>
  <circle cx='256' cy='256' r='207.469' fill='#fff'/>
  <path
    d='M342.649,196.805c7.582-7.582,7.582-19.872,0-27.453c-7.582-7.582-19.872-7.582-27.453,0	l-73.605,73.605l27.453,27.453L342.649,196.805z'
    fill='#181818'/>
  <path
    d='M244.229,250.613L135.035,359.807c-4.738,4.738-4.738,12.42,0,17.159	c2.37,2.368,5.474,3.554,8.579,3.554c3.105,0,6.211-1.185,8.579-3.554l109.194-109.194L244.229,250.613z'
    fill='#ee3840'/>
  <path
    d='M171.066,143.614c-7.582-7.582-19.872-7.582-27.453,0c-7.582,7.582-7.582,19.872,0,27.453	l99.343,99.343l27.453-27.453L171.066,143.614z'
    fill='#181818'/>
  <path
    d='M256,286.332c-16.725,0-30.332-13.607-30.332-30.332s13.607-30.332,30.332-30.332	s30.332,13.607,30.332,30.332S272.725,286.332,256,286.332z'
    fill='#524e4d'/>
  <g fill='#7a7674'>
    <path
      d='M115.261,268.133H97.062c-6.701,0-12.133-5.433-12.133-12.133s5.432-12.133,12.133-12.133h18.199 c6.701,0,12.133,5.433,12.133,12.133S121.962,268.133,115.261,268.133z'
    />
    <path
      d='M256,127.393c-6.701,0-12.133-5.433-12.133-12.133V97.062c0-6.7,5.432-12.133,12.133-12.133 c6.701,0,12.133,5.433,12.133,12.133v18.199C268.133,121.96,262.701,127.393,256,127.393z'
    />
    <path
      d='M414.938,268.133h-18.199c-6.701,0-12.133-5.433-12.133-12.133s5.432-12.133,12.133-12.133h18.199 c6.701,0,12.133,5.433,12.133,12.133S421.639,268.133,414.938,268.133z'
    />
    <path
      d='M256,427.071c-6.701,0-12.133-5.433-12.133-12.133v-18.199c0-6.7,5.432-12.133,12.133-12.133 c6.701,0,12.133,5.433,12.133,12.133v18.199C268.133,421.638,262.701,427.071,256,427.071z'
    />
  </g>
</svg>;
