import * as Phaser from "phaser";
import backgroundScreen from "../../assets/images/shattered.png";

const TEXT_BACKGROUND_WIDTH = 350;
const TEXT_BACKGROUND_HEIGHT = 80;

class WelcomeScreen extends Phaser.Scene {

  welcomeText;

  constructor() {
    super({key: 'WelcomeScreen'})
  }

  preload() {
    this.cameras.main.setBackgroundColor('#dfe0e0');
    this.load.image('backgroundWelcomeScreen', backgroundScreen);
  }

  create() {
    this.add.image(0, 0, 'backgroundWelcomeScreen').setOrigin(0).setDisplaySize(this.game.config.width, this.game.config.height);
    this.addText();
    this.addTextEventListener();
  }

  addText() {
    let textBackground = this.make.graphics({
      x: this.game.config.width / 2 - TEXT_BACKGROUND_WIDTH / 2,
      y: this.game.config.height / 2 - TEXT_BACKGROUND_HEIGHT / 2,
      add: true
    });
    textBackground.fillStyle(0x222222, 0.1);
    textBackground.fillRoundedRect(2, 4, TEXT_BACKGROUND_WIDTH, TEXT_BACKGROUND_HEIGHT, 16);
    textBackground.fillStyle(0xf87f00, 1);
    textBackground.fillRoundedRect(0, 0, TEXT_BACKGROUND_WIDTH, TEXT_BACKGROUND_HEIGHT, 16);
    this.welcomeText = this.add.text(this.game.config.width / 2, this.game.config.height / 2, 'START GAME', {
      font: '50px Arial',
      fill: '#F2F2F2',
      textAlign: 'center'
    });
    let textBounds = this.welcomeText.getBounds();
    this.welcomeText.setPosition(this.game.config.width / 2 - (textBounds.width / 2), this.game.config.height / 2 - (textBounds.height / 2));
  }

  addTextEventListener() {
    this.welcomeText.setInteractive();
    this.welcomeText.on('pointerup', () => {

      this.game.scene.stop('WelcomeScreen');
      let mathGameScene = this.scene.get('MathGame');
      mathGameScene.scene.restart();
      this.game.react.inputRef.value = '';
      this.game.react.inputRef.focus();

    });
  }
}

export default WelcomeScreen
