import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import validator from 'validator'

import { Link } from 'react-router-dom'

import { TextField } from 'redux-form-material-ui'

import { RaisedButton, Paper } from 'material-ui'

import { state } from 'aws-cognito-redux-saga'

const required = value => (value ? undefined : 'Required')
const email = value =>
  validator.isEmail(value) ? undefined : 'Not Valid Email'
const passwordMatch = (value, values) =>
  values.password !== values.passwordMatch && 'Passwords must match'
const minLength = value => (value.length >= 8 ? undefined : 'Min Length 8')

const style = {
  layout:{
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  img: {
      marginTop: 'auto',
      marginBottom: 'auto',
      padding: '16px',
      maxWidth: 'auto',
      maxHeight: '70%'
  },
  paper: {
    padding: '32px',
  },
  columnLayout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 56px)'
  },
  title: {
    fontSize: '32px',
    textAlign: 'center'
  },
  passwordInfo: {
      fontSize: '12px',
      textAlign: 'left'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  signUpButton: {
    margin: '16px',
    width: '80%'
  },
  field: {
    margin: '2px 32px'
  },
  error: {
    margin: '8px',
    color: 'rgb(200,0,0)'
  },
  validateTitle: {
    margin: '8px 32px',
    fontSize: '24px',
    textAlign: 'center'
  }
};

class SignUp extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    signUp: PropTypes.func.isRequired,
    auth: PropTypes.object,
    init: PropTypes.func
  }

  componentWillMount() {
    this.props.init()
  }

  signUp = values => {
    this.props.signUp(values.email.toLowerCase(), values.password, values.name)
  }

  signUpForm = () => {
    const { handleSubmit, auth } = this.props;
    return (
      <div style={style.columnLayout}>
        <Paper style={style.paper} zDepth={5}>
          <form style={style.form}>
            <div style={style.title}>Sign Up</div>

            <Field
              style={style.field}
              name="email"
              validate={[required, email]}
              component={TextField}
              type="email"
              floatingLabelText="Email"
            />

            <Field
              style={style.field}
              name="password"
              validate={[required, minLength]}
              component={TextField}
              type="password"
              floatingLabelText="Password"
            />
              <div style={style.passwordInfo}>
              <p style = {{color:'rgb(200,0,0)', align:'left'}}>Passwords must:</p>
              <p>• Be at least 8 characters long</p>
              <p>• Contain capital and lowercase letters</p>
              <p>• Contain a symbol</p>
              </div>
            <Field
              style={style.field}
              name="passwordMatch"
              validate={[required, passwordMatch, minLength]}
              component={TextField}
              type="password"
              floatingLabelText="Confirm Password"
            />

            <Field
                  style={style.field}
                  name="name"
                  validate={[required]}
                  component={TextField}
                  type="name"
                  floatingLabelText="Name"
            />

            <div style={style.error}>{auth.error.message}</div>

            <RaisedButton
              style={style.signUpButton}
              onClick={handleSubmit(this.signUp)}
              primary
            >
              Sign Up
            </RaisedButton>
            OR
              <RaisedButton
                  style={style.signUpButton}
                  containerElement={<Link to="/signin" />}
                  primary
              >
                  Sign In
              </RaisedButton>
          </form>
        </Paper>
      </div>
    )
  };

  signedUp = () => {
    return (
      <div style={style.layout}>
        <Paper style={style.paper} zDepth={5}>
          <div style={style.form}>
            <div style={style.validateTitle}>
            </div>
            <p>Thanks for signing up! Please sign in below to confirm your email</p>
            <RaisedButton
              style={style.signUpButton}
              containerElement={<Link to="/signin" />}
              primary
            >
              Continue to sign in
            </RaisedButton>
          </div>
        </Paper>
      </div>
    )
  }

  render() {
    const { auth } = this.props

    return (
      <div style={style.layout}>
        {auth.hasSignedUp === state.AUTH_UNKNOWN
          ? this.signUpForm()
          : this.signedUp()}
      </div>
    )
  }
}

// Decorate the form component
export default reduxForm({
  form: 'signUp'
})(SignUp)
