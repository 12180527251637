import React from 'react'
import { Form, Field } from 'react-final-form'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import TextField from 'material-ui/TextField'
import Select from 'react-select'
import axios from 'axios'

axios.defaults.baseURL = 'https://chooter.herokuapp.com';

const examOptions = [{"value": "TSA", "label": "TSA"}, {"value": "UKCAT", "label": "UKCAT"}, {"value": "BMAT", "label": "BMAT"}, {"value": "testing for graduate jobs", "label": "Testing for graduate jobs"}, {"value": "TEST", "label": "Just testing Chooter out!"}];
const TextFieldAdapter = ({ input, meta, ...rest }) => (
    <TextField
        {...input}
        {...rest}
        onChange={(event, value) => input.onChange(value)}
        errorText={meta.touched ? meta.error : ''}
    />
);
const required = value => (value ? undefined : 'Required');
const ReactSelectAdapter = ({ input, ...rest }) => (
    <Select {...input} {...rest} searchable />
);


class NewProfileForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userCreated: false,
            loading: false
        };
    }


    handleSubmit(values){
        const { auth } = this.props;
        axios.post(`/addUser`, {
    email: auth.info.username,
    name: values.firstName,
    role: values.exam.value,
    cognitoUUID: auth.info.idToken.payload.sub
            }).then(() => {this.props.onSubmit(values.exam.value, values.firstName)})

    };

    render() {
        return (

            <MuiThemeProvider muiTheme={getMuiTheme()}>
                <Form
                    onSubmit={this.handleSubmit.bind(this)}
                    render={({handleSubmit, form, submitting, pristine}) => (
                        <div className={"FirstForm"}>
                            <h2>Before we get started:</h2>
                            <br/>
                        <form onSubmit={handleSubmit}>
                            <h5>What should we call you?</h5>
                            <div>
                                <Field
                                    name="firstName"
                                    component={TextFieldAdapter}
                                    validate={required}
                                    hintText="Name"
                                    floatingLabelText="Name"
                                />
                            </div>
                            <br/>
                            <h5>What exam are you studying for?</h5>
                            <br/>
                            <div>
                                <Field
                                    name="exam"
                                    component={ReactSelectAdapter}
                                    validate={required}
                                    options={examOptions}
                                />
                            </div>
                            <br/>
                            <div>
                                <label style = {{fontSize: "10"}}>I agree to <a href="https://chooter.co.uk/privacy-policy/" target="_blank" rel="noopener noreferrer">Chooter's privacy policy </a></label>
                                <Field
                                    name="Ts&Cs"
                                    component="input"
                                    validate={required}
                                    type="checkbox"
                                />
                            </div>
                            <br/>
                            <div className="buttons">
                                <button type="submit" disabled={submitting || pristine}>
                                    Register
                                </button>
                                <br/>
                                <button
                                    type="button"
                                    onClick={form.reset}
                                    disabled={submitting || pristine}
                                >
                                    Reset
                                </button>
                            </div>
                        </form>
                        </div>
                    )}
                />
            </MuiThemeProvider>
        );
    }
}

export default NewProfileForm;