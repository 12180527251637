import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from '../Lotties/animation-w153-h151.json'

class CheckMarkAnimation extends Component {


    render(){

        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return(
                <Lottie options={defaultOptions}
                        height={250}
                        width={250}
                />
        )
    }
}

export default CheckMarkAnimation