import React, { Component } from 'react';

class Leaderboard extends Component {
  componentDidMount() {
  }
  render() {
    return (
      <div className="card" id="leaderboard">
        <h2 className="card-header">Leaderboard</h2>
        <table id="leaderboard-table">
            <h3 className="card-header">Coming Soon</h3>
        </table>
      </div>
    );
  }
}

export default Leaderboard;
