import React from "react";

export const scoreIcon = () => <svg id='Capa_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
  <path
    d='M512,256c0,37.439-8.035,73.007-22.486,105.064c-13.72,30.469-33.249,57.772-57.145,80.488	c-30.26,28.776-67.553,50.218-109.035,61.503c-7.199,1.954-14.535,3.605-21.985,4.942C286.626,510.631,271.475,512,256,512	c-9.665,0-19.205-0.533-28.588-1.588c-5.663-0.616-11.274-1.432-16.812-2.435c-15.893-2.832-31.284-7.147-46.017-12.79	c-5.089-1.933-10.104-4.044-15.026-6.301c-3.626-1.661-7.21-3.406-10.742-5.224c-3.124-1.609-6.207-3.281-9.258-5.026	c-4.765-2.706-9.435-5.569-14.002-8.568c-12.33-8.108-23.928-17.251-34.649-27.324c-0.418-0.387-0.836-0.784-1.254-1.181	c-23.939-22.758-43.478-50.082-57.219-80.593C8.025,328.944,0,293.407,0,256C0,114.615,114.615,0,256,0S512,114.615,512,256z'
    fill='#f4a14e'/>
  <path
    d='M489.514,361.064c-13.72,30.469-33.249,57.772-57.145,80.488	c-30.26,28.776-67.553,50.218-109.035,61.503c-7.199,1.954-14.535,3.605-21.985,4.942C286.626,510.631,271.475,512,256,512	c-9.665,0-19.205-0.533-28.588-1.588c-5.663-0.616-11.274-1.432-16.812-2.435c-15.893-2.832-31.284-7.147-46.017-12.79	c-5.089-1.933-10.104-4.044-15.026-6.301c-3.626-1.661-7.21-3.406-10.742-5.224c-3.124-1.609-6.207-3.281-9.258-5.026	c-4.765-2.706-9.435-5.569-14.002-8.568c-12.33-8.108-23.928-17.251-34.649-27.324c-0.418-0.387-0.836-0.784-1.254-1.181	c-23.939-22.758-43.478-50.082-57.219-80.593c14.681-18.066,31.671-34.67,50.897-49.351c3.835-2.936,7.784-5.768,11.786-8.537	c4.629-3.218,9.331-6.269,14.085-9.153c77.072-47.188,168.02-55.62,248.884-29.675h0.01c13.197,4.232,26.122,9.373,38.672,15.444	h0.021C425.65,298.402,460.925,325.726,489.514,361.064z'
    fill='#e28d40'/>
  <path
    d='M438.06,313.47H73.835c-14.344,0-25.973-11.629-25.973-25.973V66.072	c0-14.344,11.629-25.973,25.973-25.973h364.226c14.344,0,25.973,11.629,25.973,25.973v221.425	C464.033,301.842,452.404,313.47,438.06,313.47z'
    fill='#fcf8f2'/>
  <path
    d='M376.027,320.261H136.035c1.379-1.118,2.79-2.226,4.211-3.312c2.435-1.86,4.932-3.657,7.471-5.413	c2.445-1.693,4.911-3.312,7.408-4.859c0.502-0.324,1.003-0.627,1.515-0.94c48.839-29.905,106.475-35.244,157.717-18.808h0.01	c8.359,2.685,16.551,5.945,24.503,9.791h0.01c6.186,2.978,12.225,6.301,18.098,9.958	C363.562,310.784,369.925,315.308,376.027,320.261z'
    fill='#e8dbc4'/>
  <path d='M431.874,39.779H256.046v77.009h207.945V71.896C463.991,54.159,449.612,39.779,431.874,39.779z'
        fill='#34abe0'/>
  <path d='M78.149,39.779h175.828v77.009H46.032V71.896C46.032,54.159,60.411,39.779,78.149,39.779z'
        fill='#ff525d'/>
  <g fill='#ffd15c'>
    <path
      d='M438.063,33.311H73.832c-18.066,0-32.768,14.691-32.768,32.758v221.424 c0,18.066,14.702,32.768,32.768,32.768h364.23c18.066,0,32.758-14.702,32.758-32.768V66.069 C470.821,48.003,456.129,33.311,438.063,33.311z M457.237,287.493c0,10.585-8.6,19.184-19.174,19.184H73.832 c-10.574,0-19.184-8.6-19.184-19.184V66.069c0-10.574,8.61-19.174,19.184-19.174h364.23c10.574,0,19.174,8.6,19.174,19.174V287.493 z'
    />
    <rect x='47.867' y='110.498' width='208.091' height='13.584'/>
    <rect x='249.156' y='40.103' width='13.584' height='273.366'/>
  </g>
  <path
    d='M159.725,276.547h-15.633c-16.249,0-29.469-13.22-29.469-29.469v-63.719	c0-16.249,13.22-29.469,29.469-29.469h15.633c16.249,0,29.469,13.22,29.469,29.469v63.719	C189.194,263.327,175.974,276.547,159.725,276.547z M144.092,167.472c-8.759,0-15.886,7.126-15.886,15.886v63.719	c0,8.759,7.126,15.886,15.886,15.886h15.633c8.759,0,15.886-7.126,15.886-15.886v-63.719c0-8.759-7.126-15.886-15.886-15.886	H144.092z'
    fill='#ff525d'/>
  <rect x='256.042' y='110.498' width='208.091' height='13.584' fill='#ffd15c'
  />
  <path
    d='M367.905,276.547h-15.633c-16.249,0-29.469-13.22-29.469-29.469v-63.719	c0-16.249,13.22-29.469,29.469-29.469h15.633c16.249,0,29.469,13.22,29.469,29.469v63.719	C397.375,263.327,384.154,276.547,367.905,276.547z M352.273,167.472c-8.759,0-15.886,7.126-15.886,15.886v63.719	c0,8.759,7.126,15.886,15.886,15.886h15.633c8.759,0,15.886-7.126,15.886-15.886v-63.719c0-8.759-7.126-15.886-15.886-15.886	H352.273z'
    fill='#34abe0'/>
  <g fill='#ffd15c'>
    <rect x='103.131' width='13.584' height='37.531'/>
    <rect x='395.17' width='13.584' height='37.531'/>
  </g>
  <path
    d='M432.368,441.553C386.456,485.209,324.357,512,256,512c-42.235,0-82.077-10.219-117.185-28.338	c-21.713-11.201-41.618-25.412-59.162-42.099c2.069-5.099,4.368-10.115,6.896-15.026c12.56-24.451,30.584-46.477,53.697-64.136	c2.435-1.86,4.932-3.657,7.471-5.413c2.936-2.038,5.914-3.971,8.923-5.799c48.839-29.905,106.475-35.244,157.717-18.808h0.01	c8.359,2.685,16.551,5.945,24.503,9.791h0.01c28.118,13.531,53.269,34.179,72.537,61.377c0.47,0.648,0.94,1.306,1.39,1.964	c4.054,5.872,7.732,11.891,11.034,18.035C427.029,429.453,429.861,435.461,432.368,441.553z'
    fill='#ff525d'/>
  <path
    d='M376.027,320.261H136.035c1.379-1.118,2.79-2.226,4.211-3.312c2.435-1.86,4.932-3.657,7.471-5.413	c2.445-1.693,4.911-3.312,7.408-4.859h201.853C363.562,310.784,369.925,315.308,376.027,320.261z'
    fill='#edbb4c'/>
  <path
    d='M254.234,323.229c-11.045,3.96-21.828,9.07-32.172,15.402c-2.748,1.672-5.465,3.438-8.14,5.298	c-2.32,1.599-4.598,3.25-6.823,4.942c-21.107,16.123-37.564,36.237-49.027,58.556c-2.487,4.827-4.733,9.77-6.74,14.785	c-7.847,19.56-12.089,40.406-12.518,61.45c-21.713-11.201-41.618-25.412-59.162-42.099c2.069-5.099,4.368-10.115,6.896-15.026	c12.56-24.451,30.584-46.477,53.697-64.136c2.435-1.86,4.932-3.657,7.471-5.413c2.936-2.038,5.914-3.971,8.923-5.799	C186.995,332.601,220.735,323.511,254.234,323.229z'
    fill='#ef4152'/>
  <path
    d='M423.842,423.549l-8.046-2.351c-8.746-2.152-17.575-3.584-26.415-4.284	c-22.361-1.797-44.805,1.014-65.923,8.161c-14.357,4.848-28.097,11.703-40.814,20.48c-8.756,6.05-16.896,12.894-24.273,20.438	c22.758,10.47,44.492,22.883,64.961,37.063c-7.199,1.954-14.535,3.605-21.985,4.942c-17.283-11.045-35.412-20.825-54.251-29.226	c-7.858,9.906-14.43,20.532-19.686,31.639c-5.663-0.616-11.274-1.432-16.812-2.435c5.517-12.518,12.507-24.513,20.961-35.736	c-15.256-6.071-30.877-11.254-46.655-15.454c-7.826,12.288-14.608,25.13-20.323,38.4c-5.089-1.933-10.104-4.044-15.026-6.301	c5.339-12.434,11.577-24.524,18.683-36.153c-8.767-1.964-17.596-3.626-26.394-4.995c-3.5,10.533-7.607,20.846-12.288,30.898	c-4.765-2.706-9.435-5.569-14.002-8.568c3.678-8.025,6.959-16.227,9.832-24.576c-14.221-1.64-28.588-2.56-42.851-2.738l-1.63-0.01	l-1.724-0.021c0.157-0.387,0.313-0.773,0.47-1.16c2.069-5.099,4.368-10.115,6.896-15.026c14.587,0.293,29.247,1.348,43.75,3.135	c6.113-21.911,9.446-44.481,9.947-67.271c2.435-1.86,4.932-3.657,7.471-5.413c2.936-2.038,5.914-3.971,8.923-5.799l-0.01,5.308	c-0.031,25.558-3.417,50.907-10.052,75.494c10.407,1.661,20.846,3.741,31.148,6.175c19.728-28.338,44.272-52.736,73.028-72.589	c19.874-13.72,41.221-24.869,63.603-33.196h0.01c8.359,2.685,16.551,5.945,24.503,9.791l-10.136,2.435	c-24.283,8.087-47.397,19.665-68.712,34.388c-25.464,17.575-47.417,38.922-65.4,63.561c16.196,4.545,32.235,10.083,47.867,16.52	c9.185-10.073,19.571-19.122,30.898-26.937c18.338-12.664,38.64-21.682,59.831-26.843c13.678-3.365,27.721-5.099,41.859-5.183	c12.11-0.094,24.283,1.055,36.342,3.438c0.47,0.648,0.94,1.306,1.39,1.964C416.862,411.387,420.54,417.405,423.842,423.549z'
    fill='#5c5e70'/>
  <circle cx='357.48' cy='429.568' r='34.327' fill='#ff6e7c'/>
  <path
    d='M389.382,416.914c-22.359-1.793-44.806,1.016-65.924,8.164c0.991-7.687,4.539-14.571,9.756-19.788	c13.681-3.36,27.726-5.092,41.859-5.18C381.519,403.948,386.599,409.855,389.382,416.914z'
    fill='#797b89'/>
</svg>;
