import React from 'react';
import validate from 'validate.js';
import PropTypes from 'prop-types';

class Answer extends React.Component {
  state = {
    isPhotoAnswer: false
  };

  componentDidMount() {
    const {answer} = this.props;
    this.checkIfPhotoAnswer(answer)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.answer !== this.props.answer) {
      this.checkIfPhotoAnswer(this.props.answer);
    }
  }

  checkIfPhotoAnswer(answer) {
    let isURL = validate({website: answer}, {
      website: {
        url: {
          allowLocal: true,
        }
      }
    });

    if (isURL === undefined) {
      this.setState({isPhotoAnswer: true})
    } else {
      this.setState({isPhotoAnswer: false})
    }
  }

  renderAnswer(answer, isPhotoAnswer) {
    const {checked, onChange} = this.props;
    let labelContent = answer;
    if (isPhotoAnswer) {
      labelContent = <img src={answer} alt="answer"/>
    }
    return (
      <React.Fragment>
        <input type="checkbox"
               id={this.props.id}
               value={answer}
               checked={checked}
               onChange={onChange}/>
        <label className={checked ? 'checked' : ''} htmlFor={this.props.id}>{labelContent}</label>
      </React.Fragment>
    )
  }

  render() {
    const {isPhotoAnswer} = this.state;
    const {answer} = this.props;

    return (
      <React.Fragment>
        <div className="answer">
          {isPhotoAnswer ? this.renderAnswer(answer, true) : this.renderAnswer(answer, false)}
        </div>
      </React.Fragment>
    )
  }
}

Answer.propTypes = {
  answer: PropTypes.string
};

export default Answer
