import React from "react";
import _ from 'lodash';
import axios from 'axios';
import {Button} from 'grommet';
import QuestionContainer from "../QuestionContainer";
import LoadingScreen from "../../components/LoadingScreen";
import CheckMarkAnimation from "../../components/CheckMarkAnimation";
import BrokenTwigAnimation from "../../components/BrokenTwigAnimation";
import LessonStarRating from "../../components/Stars";
import {Box, Meter} from 'grommet';
import { Paper } from 'material-ui'
import {Revert, Close} from 'grommet-icons'


axios.defaults.baseURL = 'https://chooter.herokuapp.com';

class NewLessonContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMultiSelect: true,
            questionsArray: [],
            usedQuestionsArray: [],
            questionsCorrect: 0,
            questionsAnswered: 0,
            isLessonEnded: false,
            answerReview: false,
            lessonID: 0,
            questionID: 0,
            StepID:0,
            questionNumber:0,
            loadingQuestions: true,
            loadingLesson: false,
            loading: true,
            scoreUpdated: false,
            outOfQuestions: false,
            lessonSlides:[],
            fetched: false,
            lessonRendered: false,
            lessonInstanceUUID: null,
            instanceCreated: false,
            instanceUpdated: false,
            nextSlide: true,
            showLessonSlides: true,
            revealVisible: 'visible',
            slideAnswer: 0,
            buttonsActive: true,
            lessonDataUpdated: false,
            userLessonData: []
        };
        this.endLesson = this.endLesson.bind(this);
        this.startLesson = this.startLesson.bind(this);
        this.renderLessonQuestion = this.renderLessonQuestion.bind(this);
        this.reviewAnsweredQuestion = this.reviewAnsweredQuestion.bind(this)
        this.nextSlide = this.nextSlide.bind(this);
        this.previousSlide = this.previousSlide.bind(this);
        this.revealVisible = this.revealVisible.bind(this);
        this.moveToQuestions = this.moveToQuestions.bind(this);
        //this.renderChunk = this.renderChunk.bind(this);



    }

    componentDidMount(){
        this.startLesson()
    }

    parentGetAnswers(childGetAnswers) {
        this.childGetAnswers = childGetAnswers;
    }

    startLesson(){
        let now = Date.now();
        this.setState({answerReview: false, startGameTime: now})
    };

    endLesson = () => {
        this.setState({
            answerReview: false,
            fetched: false,
            c:1});
    };

    renderQuestionsCounter() {
        return (
            <div className="questionsAnswered" style ={{paddingTop: '2%', paddingBottom: '4%'}}>
                <h3>CURRENT SCORE:</h3>
                <h3>{this.state.questionsCorrect}/{this.state.questionsAnswered}</h3>
            </div>
        )
    }

    renderLessonQuestionContainer(i) {
        const {loadingQuestions} = this.state;
        const auth = this.props.auth;
        if (loadingQuestions) {
            axios.get(`/getUserLessonData`, {
                params: {
                    cognitoUUID: auth.info.idToken.payload.sub
                }
            }).then(res => {
                if (res.data) {
                    this.setState({
                        userLessonData: res.data,
                    });
                    console.log(this.state.lessonData)
                }});
            axios.get(`/getLessonQuestions`, {
                params: {
                    lessonID: i
                }
            })
                .then(res => {
                        const importedQuestions = res.data;
                        this.setState({questionsArray: importedQuestions, loadingQuestions: false});
                        console.log(importedQuestions);

                    }
                );
        }

        if (loadingQuestions) {
            return <LoadingScreen/>
        }
        else {
            return this.renderLessonQuestion(this.state.questionsArray[this.state.questionNumber])
        }

    }

    renderLessonQuestion(answerObject) {
        const {isMultiSelect, answerReview, outOfQuestions, lessonInstanceUUID, instanceUpdated} = this.state;
        if(outOfQuestions){
            return <Paper style ={{width: "70vw", marginLeft: "auto", marginRight: "auto", marginTop: "3vh",
                textAlign: "center"}}>
                <h2 style = {{color: "orange", padding: '7%'}}>All out of questions for this lesson!</h2>
                <br/>
                <div className="controls">
                    {this.renderQuestionsCounter()}
                </div>
                <Button
                    style = {{margin: '3%'}}
                    onClick={this.props.end()}
                    label={'End Lesson'}/>
            </Paper>
        }
        else if(!answerReview){
            return (
                    <div className="lessonSlideWrapper">
                        <div className="lessonQuestion">
                        <QuestionContainer isMultiSelect={isMultiSelect}
                                              currentQuestion={answerObject}
                                              shareMethod={this.parentGetAnswers.bind(this)}
                        />
                        <Button className="submit" onClick={() => this.setState({answerReview: true})}
                                    label={'SUBMIT'}/>
                        {this.renderQuestionsCounter()}
                            <Button
                                style = {{margin: '2%'}}
                                onClick={this.props.end()}
                                label={'End Lesson'}/>
                        </div>
                    </div>
            )
        }else{
            if(!instanceUpdated){
                this.setState({instanceUpdated: true});
            axios.post(`/updateLessonInstance`, {
                uuid: lessonInstanceUUID,
                answered: true
            });}
            return this.reviewAnsweredQuestion(answerObject)
        }
    }

    stepToNextQuestion(){

        if(this.state.questionNumber < this.state.questionsArray.length-1) {
            this.setState({questionNumber: this.state.questionNumber + 1, answerReview: false, scoreUpdated: false, instanceUpdated: false})
        } else {
            this.setState({outOfQuestions: true})

        }
    }

    reviewAnsweredQuestion(answerObject) {
        let {answersArray} = this.childGetAnswers();
        console.log(answersArray);
        let correctAnswers = [];
        _.forEach(answerObject.AnswerOptions, function(option){
            if(option.correct){
                correctAnswers.push(option.text);}
        });
        let isAnswerCorrect = _.isEqual(_.sortBy(answersArray), _.sortBy(correctAnswers));

        if(isAnswerCorrect){
            if(!this.state.scoreUpdated)
            {this.setState({questionsCorrect : this.state.questionsCorrect + 1, questionsAnswered : this.state.questionsAnswered + 1, scoreUpdated: true});}
            return(<Paper style ={{width: "50vw", marginLeft: "auto", marginRight: "auto", marginTop: "3vh",
                    textAlign: "center"}}>
                    <br/>
                    <CheckMarkAnimation style = {{width: "50%"}}/>
                    <h4>CORRECT!</h4>
                    <br/>
                    <Button
                        style = {{margin: "5%"}}
                        onClick={() => this.stepToNextQuestion()}
                        label={'Next Question'}/>
                </Paper>)}
        else{
            if(!this.state.scoreUpdated)
            {this.setState({questionsAnswered : this.state.questionsAnswered + 1, scoreUpdated: true});}
            return(<Paper style ={{width: "50vw", marginLeft: "auto", marginRight: "auto", marginTop: "3vh",
                textAlign: "center"}}>
            <br/>
            <BrokenTwigAnimation style = {{width: "80%"}}/>
            <h4>Oops not quite - it was:</h4>
            <br/>
            <h5>{correctAnswers}</h5>
            <Button
                style = {{margin: "5%"}}
                onClick={() => this.stepToNextQuestion()}
                label={'Next Question'}/>
                </Paper>)}

    };

    getSlides(i){
        const {fetched} = this.state;
        if (!fetched) {
            axios.post(`/updateLesson`, {
                lessonID: i,
                served: true,
                answered: false
            });
            axios.get(`/getLessonSlides`, {
                params: {
                    lessonID: i
                }}).then(res => {
                    let orderArray = res.data;
                    console.log(orderArray);
                this.setState({lessonSlides: orderArray.sort(function(a,b){return a.LessonId - b.LessonId}), fetched: true, loading: false});
            });
    }}

    createLessonInstance(i){
        const {instanceCreated} = this.state;

        if(!instanceCreated){
            this.setState({instanceCreated: true});
            axios.post(`/addLessonInstance`,{
                lessonID: i,
                lessonTitle: this.props.lessonTitle,
                userCogUUID: this.props.auth.info.idToken.payload.sub
           }).then(res => {
               console.log(res);
                this.setState({instanceCreated: true, lessonInstanceUUID:res.data.uuid });
            })
        }
    }

    renderSlideQuestion(){
        const {lessonSlides, StepID, buttonsActive} = this.state;
        let questionsAnswers = [];
        if(lessonSlides[StepID].answerA.length>0){questionsAnswers.push(lessonSlides[StepID].answerA);}
        if(lessonSlides[StepID].answerB.length>0){questionsAnswers.push(lessonSlides[StepID].answerB);}
        if(lessonSlides[StepID].answerC.length>0){questionsAnswers.push(lessonSlides[StepID].answerC);}
        if(lessonSlides[StepID].answerD.length>0){questionsAnswers.push(lessonSlides[StepID].answerD);}
        if(lessonSlides[StepID].answerE.length>0){questionsAnswers.push(lessonSlides[StepID].answerE);}
        let questionButtons = questionsAnswers.map((object,i) =>
            <div>
            <Button color = "#f07f0e"
                    margin = 'small' key = {i} active = {buttonsActive}
                    label = {object} onClick={() => this.answerSlideQuestion(object)}/>
            </div>
        );

        return(
            <div>
                {questionButtons}
            </div>
        )

    }

    updateUserLessonData(){
        this.setState({lessonDataUpdated: true});
        const axiosCall = this.props.axiosCall;
        let axiosArray = {
            cognitoUUID: this.props.auth.info.idToken.payload.sub,
        };
        axiosArray[axiosCall] = true;
        axios.post(`/updateUserLessonData`, {
            axiosArray: axiosArray
        })}

    answerSlideQuestion(answer){
        const {lessonSlides, StepID} = this.state;
    if(answer === lessonSlides[StepID].answer){
        this.setState({slideAnswer: 2, buttonsActive: false, revealVisible: 'visible'})
    }else{this.setState({slideAnswer: 1, buttonsActive: false, revealVisible: 'visible'})}
    }

    nextSlide(){
        const {StepID} = this.state;
        this.setState({StepID: StepID + 1, revealVisible: 'hidden', slideAnswer: 0});
    }

    previousSlide(){
        const {StepID} = this.state;
        this.setState({StepID: StepID - 1, revealVisible: 'hidden', slideAnswer: 0})
    }

    revealVisible(){
        this.setState({revealVisible: 'visible'})
    }

    moveToQuestions(){
        this.setState({showLessonSlides: false})
    }

    renderLesson(i){
        const {loading, lessonSlides, lessonDataUpdated, StepID, revealVisible, slideAnswer, active, lessonInstanceUUID} = this.state;
        this.getSlides(i);
        this.createLessonInstance(i);

        if(!lessonDataUpdated && (StepID === lessonSlides.length - 1)){
            this.updateUserLessonData()
        }
        if(loading){
            return(<LoadingScreen/>)
        }
        return(
            <div className="lessonSlideWrapperInner" style = {{marginTop: "20px"}}>
                { StepID >= 1 ? (
                    <div className="backButton">
                    <Button
                        className="backButtonStyle"
                        icon = {<Revert/>}
                        onClick={this.previousSlide}
                        label={'Back'}/>
                    </div>
                ):(
                    <div className="backButton">
                        <Button
                            className="backButtonStyle"
                            icon = {<Close/>}
                            onClick={this.props.end()}
                            label={'Close lesson'}/>
                    </div>
                )}
                <h1>{lessonSlides[StepID].title}</h1>
                <br/>
                <p>{lessonSlides[StepID].mainContent}</p>
                <br/>
                {lessonSlides[StepID].highlight.length > 0 ? (
                    <div>
                    <Box background={'light-3'}>{lessonSlides[StepID].highlight}
                    </Box>
                    <br/>
                    </div>
                ) : (
                    ''
                )}
                {slideAnswer === 2 ? (
                    <Box background={'lightgreen'}>{'Correct!'}</Box>
                ) : ( slideAnswer === 1 ? ( <Box background={'red'}>{`Oops, not quite - the answer was ${lessonSlides[StepID].answer}`}</Box>) :(
                    '')
                )}
                    {lessonSlides[StepID].reveal.length>0 ? (
                        <div style={{visibility: revealVisible}}>
                            <Box background={'dark-3'} >
                        <p style = {{color: 'white'}}> {lessonSlides[StepID].reveal} </p>
                            </Box>
                            <br/>
                        </div>
                    ) : (
                        ''
                    )}
                    {lessonSlides[StepID].image.length>0 ? (
                            <img src={lessonSlides[StepID].image} alt={lessonSlides[StepID].title} style = {{maxWidth: '70vw', height: 'auto'}}/>
                    ) : (
                        ''
                    )}
                {lessonSlides[StepID].answer.length > 0 && slideAnswer === 0 ? (''):(
                    StepID < lessonSlides.length - 1 ? (
                        lessonSlides[StepID].reveal.length> 0 && revealVisible === 'hidden' ? (
                    <Button
                    onClick={this.revealVisible} //props
                    label={'Reveal'}/>) : (
                    <Button
                    onClick={this.nextSlide} //props
                    label={'Next'}/>)
                    ) : (
                    <div>
                    <br/>
                    <LessonStarRating
                        lessonInstanceUUID = {lessonInstanceUUID}
                    />
                    <br/>
                        {!(i === 13 || i > 15) ? (
                    <div>
                    <Button
                    onClick = {this.moveToQuestions}
                    label = {`Let's Practice!`}/>
                    <br/>
                    </div>) : ('')}
                    <Button
                    style = {{margin: '2%'}}
                    onClick={this.props.end()}
                    label={'End Lesson'}/>
                    </div>
                    ))}


                {lessonSlides[StepID].answer.length>0 ? (
                    <div>
                        {this.renderSlideQuestion()}
                    </div>
                ) : (
                    ''
                )}
                <div className="progressBar">
                <h2>{StepID+1}/{lessonSlides.length}</h2>
                <Meter
                    type='bar'
                    values={[
                        {
                            color: '#7D4CDB',
                            label: 'First',
                            value: StepID+1,
                            highlight: (active === 'First'),
                            onHover: over => this.setState({ active: over ? 'First' : undefined }),
                        },
                        {
                            color: '#b9b3ff',
                            label: 'Second',
                            value: lessonSlides.length - (StepID+1),
                            highlight: (active === 'Second'),
                            onHover: over => this.setState({ active: over ? 'Second' : undefined }),
                        },
                    ]}
                />
                </div>
            </div>
        )}




    renderLessonContainer() {
    const {showLessonSlides} = this.state;
        if(showLessonSlides){
        return (
            <div className="lessonSlideWrapper">
                    {this.renderLesson(this.props.lessonID)}
            </div>)}
        else{
            return(
                <div>
                    {this.renderLessonQuestionContainer(this.props.lessonID)}
                </div>
            )
        }

    }

    render() {

        return(
            <div>
                {this.renderLessonContainer()}
            </div>  )}
}

export default NewLessonContainer
